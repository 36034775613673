
import Stalls from './components/Stalls';
import Circle1 from './components/Circle1';
import Circle2 from './components/Circle2';
import Circle3 from './components/Circle3';
import Gallery from './components/Gallery';
import { Section } from './interfaces';

export const sections: Section[] = [
	{
		name: 'stalls',
		section: 'stalls',
		seats: Stalls,
	},
	{
		name: 'circle 1',
		section: 'circle1',
		seats: Circle1,
	},
	{
		name: 'circle 2',
		section: 'circle2',
		seats: Circle2,
	},
	{
		name: 'circle 3',
		section: 'circle3',
		seats: Circle3,
	},
	{
		name: 'gallery',
		section: 'gallery',
		seats: Gallery,
	},
];

export const EVENT = 1;