/** @format */

import * as React from 'react';
import seats from './seats';

function Gallery(props: any) {
	const [selected, setSelected] = React.useState<string[]>([]);

	// React.useMemo(() => {
	// 	console.log('selected', selected);
	// }, [selected]);

	const onClickSeat = (e: any, id: string) => {
		const target = e.target as any;
		if (target.classList.contains('selected')) {
			target.classList.remove('selected');
			const newSelected = selected.filter(seat => seat !== id);
			setSelected(newSelected);
		} else {
			target.classList.add('selected');
			setSelected([...selected, id]);
		}
	};

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={props.width}
			height={props.height}
			viewBox="0 0 569.733 170.475"
			{...props}
		>
			<g
				transform="translate(24.897 19.554)"
				fill="none"
				stroke="#000"
				strokeWidth={0.265}
			>
				{seats.map(seat => (
					<circle
						key={seat.id}
						id={seat.id}
						cx={seat.x}
						cy={seat.y}
						r={5}
						fill="white"
						className="seat"
						onClick={e => onClickSeat(e, seat.id)}
					/>
				))}
			</g>
		</svg>
	);
}

export default Gallery;
