/** @format */

import * as React from 'react';

interface StallsProps {
	width: number;
	height: number;
}

const Stalls: React.FC<StallsProps> = ({ width, height }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 536.256 638.022"
			id="svg60187"
		>
			<style id="style8602" />
			<g id="layer1" transform="translate(-119.135 129.48)">
				<circle
					id="ech-stall-d-4"
					className="seat ech-stall-d"
					cx={216.535}
					cy={-63.28}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-5"
					className="seat ech-stall-d"
					cx={228.885}
					cy={-59.426}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-6"
					className="seat ech-stall-d"
					cx={241.321}
					cy={-55.902}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-7"
					className="seat ech-stall-d"
					cx={253.835}
					cy={-52.67}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-8"
					className="seat ech-stall-d"
					cx={266.417}
					cy={-49.716}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-9"
					className="seat ech-stall-d"
					cx={279.059}
					cy={-47.039}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-10"
					className="seat ech-stall-d"
					cx={291.758}
					cy={-44.641}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-11"
					className="seat ech-stall-d"
					cx={304.507}
					cy={-42.529}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-12"
					className="seat ech-stall-d"
					cx={317.3}
					cy={-40.711}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-13"
					className="seat ech-stall-d"
					cx={330.134}
					cy={-39.199}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-14"
					className="seat ech-stall-d"
					cx={343}
					cy={-38.007}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-15"
					className="seat ech-stall-d"
					cx={355.894}
					cy={-37.149}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-16"
					className="seat ech-stall-d"
					cx={368.805}
					cy={-36.644}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-17"
					className="seat ech-stall-d"
					cx={381.798}
					cy={-36.51}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-18"
					className="seat ech-stall-d"
					cx={394.708}
					cy={-36.801}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-19"
					className="seat ech-stall-d"
					cx={407.62}
					cy={-37.328}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-20"
					className="seat ech-stall-d"
					cx={420.518}
					cy={-38.131}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-21"
					className="seat ech-stall-d"
					cx={433.394}
					cy={-39.234}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-22"
					className="seat ech-stall-d"
					cx={446.245}
					cy={-40.576}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-23"
					className="seat ech-stall-d"
					cx={459.064}
					cy={-42.214}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-24"
					className="seat ech-stall-d"
					cx={471.849}
					cy={-44.097}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-25"
					className="seat ech-stall-d"
					cx={484.594}
					cy={-46.242}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-26"
					className="seat ech-stall-d"
					cx={497.292}
					cy={-48.656}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-27"
					className="seat ech-stall-d"
					cx={509.936}
					cy={-51.329}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-28"
					className="seat ech-stall-d"
					cx={522.52}
					cy={-54.269}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-29"
					className="seat ech-stall-d"
					cx={535.033}
					cy={-57.503}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-30"
					className="seat ech-stall-d"
					cx={547.472}
					cy={-61.023}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stall-d-31"
					className="seat ech-stall-d"
					cx={559.818}
					cy={-64.893}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-1"
					className="seat ech-stall-c"
					cx={184.754}
					cy={-95.041}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-2"
					className="seat ech-stall-c"
					cx={196.72}
					cy={-89.99}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-3"
					className="seat ech-stall-c"
					cx={208.851}
					cy={-85.393}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-4"
					className="seat ech-stall-c"
					cx={221.119}
					cy={-81.181}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-5"
					className="seat ech-stall-c"
					cx={233.501}
					cy={-77.321}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-6"
					className="seat ech-stall-c"
					cx={245.982}
					cy={-73.797}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-7"
					className="seat ech-stall-c"
					cx={258.55}
					cy={-70.597}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-8"
					className="seat ech-stall-c"
					cx={271.195}
					cy={-67.716}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-9"
					className="seat ech-stall-c"
					cx={283.907}
					cy={-65.148}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-10"
					className="seat ech-stall-c"
					cx={296.678}
					cy={-62.89}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-11"
					className="seat ech-stall-c"
					cx={309.5}
					cy={-60.942}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-12"
					className="seat ech-stall-c"
					cx={322.365}
					cy={-59.302}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-13"
					className="seat ech-stall-c"
					cx={335.265}
					cy={-57.969}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-14"
					className="seat ech-stall-c"
					cx={348.193}
					cy={-56.944}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-15"
					className="seat ech-stall-c"
					cx={361.142}
					cy={-56.225}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-16"
					className="seat ech-stall-c"
					cx={374.105}
					cy={-55.812}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-17"
					className="seat ech-stall-c"
					cx={387.129}
					cy={-55.738}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-18"
					className="seat ech-stall-c"
					cx={400.096}
					cy={-56.01}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-19"
					className="seat ech-stall-c"
					cx={413.054}
					cy={-56.475}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-20"
					className="seat ech-stall-c"
					cx={425.998}
					cy={-57.303}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-21"
					className="seat ech-stall-c"
					cx={438.918}
					cy={-58.415}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-22"
					className="seat ech-stall-c"
					cx={451.805}
					cy={-59.836}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-23"
					className="seat ech-stall-c"
					cx={464.658}
					cy={-61.585}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-24"
					className="seat ech-stall-c"
					cx={477.466}
					cy={-63.6}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-25"
					className="seat ech-stall-c"
					cx={490.216}
					cy={-65.969}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-26"
					className="seat ech-stall-c"
					cx={502.912}
					cy={-68.628}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-27"
					className="seat ech-stall-c"
					cx={515.534}
					cy={-71.609}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-28"
					className="seat ech-stall-c"
					cx={528.075}
					cy={-74.897}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-29"
					className="seat ech-stall-c"
					cx={540.524}
					cy={-78.533}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-30"
					className="seat ech-stall-c"
					cx={552.873}
					cy={-82.495}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-31"
					className="seat ech-stall-c"
					cx={565.107}
					cy={-86.803}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-32"
					className="seat ech-stall-c"
					cx={577.204}
					cy={-91.486}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-c-33"
					className="seat ech-stall-c"
					cx={589.122}
					cy={-96.642}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-1"
					className="seat ech-stall-c"
					cx={178.734}
					cy={-119.867}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-2"
					className="seat ech-stall-c"
					cx={190.548}
					cy={-114.485}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-3"
					className="seat ech-stall-b"
					cx={202.534}
					cy={-109.533}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-4"
					className="seat ech-stall-b"
					cx={214.663}
					cy={-104.95}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-5"
					className="seat ech-stall-b"
					cx={226.915}
					cy={-100.708}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-6"
					className="seat ech-stall-b"
					cx={239.276}
					cy={-96.796}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-7"
					className="seat ech-stall-b"
					cx={251.734}
					cy={-93.207}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-8"
					className="seat ech-stall-b"
					cx={264.279}
					cy={-89.937}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-9"
					className="seat ech-stall-b"
					cx={276.904}
					cy={-86.985}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-10"
					className="seat ech-stall-b"
					cx={289.598}
					cy={-84.354}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-11"
					className="seat ech-stall-b"
					cx={302.355}
					cy={-82.043}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-12"
					className="seat ech-stall-b"
					cx={315.166}
					cy={-80.057}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-13"
					className="seat ech-stall-b"
					cx={328.025}
					cy={-78.398}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-14"
					className="seat ech-stall-b"
					cx={340.921}
					cy={-77.069}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-15"
					className="seat ech-stall-b"
					cx={353.847}
					cy={-76.074}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-16"
					className="seat ech-stall-b"
					cx={366.795}
					cy={-75.419}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-17"
					className="seat ech-stall-b"
					cx={379.781}
					cy={-75.094}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-18"
					className="seat ech-stall-b"
					cx={392.784}
					cy={-75.146}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-19"
					className="seat ech-stall-b"
					cx={405.744}
					cy={-75.517}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-20"
					className="seat ech-stall-b"
					cx={418.687}
					cy={-76.241}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-21"
					className="seat ech-stall-b"
					cx={431.608}
					cy={-77.309}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-22"
					className="seat ech-stall-b"
					cx={444.496}
					cy={-78.71}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-23"
					className="seat ech-stall-b"
					cx={457.345}
					cy={-80.436}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-24"
					className="seat ech-stall-b"
					cx={470.142}
					cy={-82.51}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-25"
					className="seat ech-stall-b"
					cx={482.879}
					cy={-84.928}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-26"
					className="seat ech-stall-b"
					cx={495.554}
					cy={-87.654}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-27"
					className="seat ech-stall-b"
					cx={508.149}
					cy={-90.727}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-28"
					className="seat ech-stall-b"
					cx={520.663}
					cy={-94.115}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-29"
					className="seat ech-stall-b"
					cx={533.079}
					cy={-97.846}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-30"
					className="seat ech-stall-b"
					cx={545.394}
					cy={-101.897}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-31"
					className="seat ech-stall-b"
					cx={557.59}
					cy={-106.294}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-32"
					className="seat ech-stall-b"
					cx={569.655}
					cy={-111.044}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-33"
					className="seat ech-stall-b"
					cx={581.565}
					cy={-116.176}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-b-34"
					className="seat ech-stall-b"
					cx={593.29}
					cy={-121.74}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-4"
					className="seat ech-stall-a"
					cx={220.787}
					cy={-122.919}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-5"
					className="seat ech-stall-a"
					cx={233.05}
					cy={-118.81}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-6"
					className="seat ech-stall-a"
					cx={245.429}
					cy={-115.117}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-7"
					className="seat ech-stall-a"
					cx={257.907}
					cy={-111.782}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-8"
					className="seat ech-stall-a"
					cx={270.468}
					cy={-108.779}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-9"
					className="seat ech-stall-a"
					cx={283.101}
					cy={-106.096}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-10"
					className="seat ech-stall-a"
					cx={295.797}
					cy={-103.728}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-11"
					className="seat ech-stall-a"
					cx={308.546}
					cy={-101.671}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-12"
					className="seat ech-stall-a"
					cx={321.342}
					cy={-99.926}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-13"
					className="seat ech-stall-a"
					cx={334.177}
					cy={-98.495}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-14"
					className="seat ech-stall-a"
					cx={347.042}
					cy={-97.379}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-15"
					className="seat ech-stall-a"
					cx={359.932}
					cy={-96.582}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-16"
					className="seat ech-stall-a"
					cx={372.837}
					cy={-96.109}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-17"
					className="seat ech-stall-a"
					cx={385.81}
					cy={-95.981}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-18"
					className="seat ech-stall-a"
					cx={398.724}
					cy={-96.208}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-19"
					className="seat ech-stall-a"
					cx={411.627}
					cy={-96.708}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-20"
					className="seat ech-stall-a"
					cx={424.512}
					cy={-97.55}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-21"
					className="seat ech-stall-a"
					cx={437.374}
					cy={-98.709}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-22"
					className="seat ech-stall-a"
					cx={450.203}
					cy={-100.204}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-23"
					className="seat ech-stall-a"
					cx={462.989}
					cy={-102.02}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-24"
					className="seat ech-stall-a"
					cx={475.723}
					cy={-104.156}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-25"
					className="seat ech-stall-a"
					cx={488.396}
					cy={-106.638}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-26"
					className="seat ech-stall-a"
					cx={501.001}
					cy={-109.448}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-27"
					className="seat ech-stall-a"
					cx={513.527}
					cy={-112.594}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-28"
					className="seat ech-stall-a"
					cx={525.959}
					cy={-116.099}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-29"
					className="seat ech-stall-a"
					cx={538.272}
					cy={-120.01}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-a-30"
					className="seat ech-stall-a"
					cx={550.455}
					cy={-124.348}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-4"
					className="seat ech-stall-e"
					cx={221.381}
					cy={-41.151}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-5"
					className="seat ech-stall-e"
					cx={233.851}
					cy={-37.361}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-6"
					className="seat ech-stall-e"
					cx={246.413}
					cy={-33.93}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-7"
					className="seat ech-stall-e"
					cx={259.054}
					cy={-30.814}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-8"
					className="seat ech-stall-e"
					cx={271.765}
					cy={-27.998}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-9"
					className="seat ech-stall-e"
					cx={284.537}
					cy={-25.477}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-10"
					className="seat ech-stall-e"
					cx={297.364}
					cy={-23.252}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-11"
					className="seat ech-stall-e"
					cx={310.239}
					cy={-21.326}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-12"
					className="seat ech-stall-e"
					cx={323.156}
					cy={-19.707}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-13"
					className="seat ech-stall-e"
					cx={336.108}
					cy={-18.403}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-14"
					className="seat ech-stall-e"
					cx={349.089}
					cy={-17.426}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-15"
					className="seat ech-stall-e"
					cx={362.09}
					cy={-16.787}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-16"
					className="seat ech-stall-e"
					cx={375.14}
					cy={-16.496}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-17"
					className="seat ech-stall-e"
					cx={388.184}
					cy={-16.599}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-18"
					className="seat ech-stall-e"
					cx={401.193}
					cy={-17.002}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-19"
					className="seat ech-stall-e"
					cx={414.195}
					cy={-17.667}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-20"
					className="seat ech-stall-e"
					cx={427.18}
					cy={-18.575}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-21"
					className="seat ech-stall-e"
					cx={440.144}
					cy={-19.762}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-22"
					className="seat ech-stall-e"
					cx={453.084}
					cy={-21.197}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-23"
					className="seat ech-stall-e"
					cx={465.991}
					cy={-22.907}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-24"
					className="seat ech-stall-e"
					cx={478.862}
					cy={-24.868}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-25"
					className="seat ech-stall-e"
					cx={491.687}
					cy={-27.095}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-26"
					className="seat ech-stall-e"
					cx={504.463}
					cy={-29.598}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-27"
					className="seat ech-stall-e"
					cx={517.185}
					cy={-32.37}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-28"
					className="seat ech-stall-e"
					cx={529.839}
					cy={-35.439}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-29"
					className="seat ech-stall-e"
					cx={542.412}
					cy={-38.833}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-e-30"
					className="seat ech-stall-e"
					cx={554.897}
					cy={-42.582}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-5"
					className="seat ech-stall-f"
					cx={227.433}
					cy={-19.697}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-6"
					className="seat ech-stall-f"
					cx={239.927}
					cy={-15.886}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-7"
					className="seat ech-stall-f"
					cx={252.524}
					cy={-12.479}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-8"
					className="seat ech-stall-f"
					cx={265.209}
					cy={-9.424}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-9"
					className="seat ech-stall-f"
					cx={277.968}
					cy={-6.7}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-10"
					className="seat ech-stall-f"
					cx={290.791}
					cy={-4.298}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-11"
					className="seat ech-stall-f"
					cx={303.669}
					cy={-2.215}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-12"
					className="seat ech-stall-f"
					cx={316.596}
					cy={-0.453}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-13"
					className="seat ech-stall-f"
					cx={329.561}
					cy={0.985}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-14"
					className="seat ech-stall-f"
					cx={342.56}
					cy={2.094}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-15"
					className="seat ech-stall-f"
					cx={355.582}
					cy={2.868}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-16"
					className="seat ech-stall-f"
					cx={368.622}
					cy={3.299}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-17"
					className="seat ech-stall-f"
					cx={381.731}
					cy={3.38}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-18"
					className="seat ech-stall-f"
					cx={394.764}
					cy={3.077}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-19"
					className="seat ech-stall-f"
					cx={407.799}
					cy={2.559}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-20"
					className="seat ech-stall-f"
					cx={420.822}
					cy={1.779}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-21"
					className="seat ech-stall-f"
					cx={433.828}
					cy={0.743}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-22"
					className="seat ech-stall-f"
					cx={446.81}
					cy={-0.545}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-23"
					className="seat ech-stall-f"
					cx={459.764}
					cy={-2.081}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-24"
					className="seat ech-stall-f"
					cx={472.684}
					cy={-3.89}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-25"
					className="seat ech-stall-f"
					cx={485.566}
					cy={-5.953}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-26"
					className="seat ech-stall-f"
					cx={498.404}
					cy={-8.28}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-27"
					className="seat ech-stall-f"
					cx={511.19}
					cy={-10.877}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-28"
					className="seat ech-stall-f"
					cx={523.917}
					cy={-13.753}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-29"
					className="seat ech-stall-f"
					cx={536.565}
					cy={-16.971}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-f-30"
					className="seat ech-stall-f"
					cx={549.131}
					cy={-20.542}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-4"
					className="seat ech-stall-g"
					cx={222.711}
					cy={-1.309}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-5"
					className="seat ech-stall-g"
					cx={235.078}
					cy={2.322}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-6"
					className="seat ech-stall-g"
					cx={247.529}
					cy={5.607}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-7"
					className="seat ech-stall-g"
					cx={260.056}
					cy={8.586}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-8"
					className="seat ech-stall-g"
					cx={272.647}
					cy={11.277}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-9"
					className="seat ech-stall-g"
					cx={285.294}
					cy={13.684}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-10"
					className="seat ech-stall-g"
					cx={297.992}
					cy={15.808}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-11"
					className="seat ech-stall-g"
					cx={310.735}
					cy={17.646}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-12"
					className="seat ech-stall-g"
					cx={323.515}
					cy={19.19}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-13"
					className="seat ech-stall-g"
					cx={336.329}
					cy={20.434}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-14"
					className="seat ech-stall-g"
					cx={349.169}
					cy={21.369}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-15"
					className="seat ech-stall-g"
					cx={362.028}
					cy={21.984}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-16"
					className="seat ech-stall-g"
					cx={374.931}
					cy={22.269}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-17"
					className="seat ech-stall-g"
					cx={387.833}
					cy={22.184}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-18"
					className="seat ech-stall-g"
					cx={400.697}
					cy={21.791}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-19"
					className="seat ech-stall-g"
					cx={413.556}
					cy={21.163}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-20"
					className="seat ech-stall-g"
					cx={426.399}
					cy={20.286}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-21"
					className="seat ech-stall-g"
					cx={439.223}
					cy={19.152}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-22"
					className="seat ech-stall-g"
					cx={452.026}
					cy={17.773}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-23"
					className="seat ech-stall-g"
					cx={464.796}
					cy={16.139}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-24"
					className="seat ech-stall-g"
					cx={477.533}
					cy={14.263}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-25"
					className="seat ech-stall-g"
					cx={490.229}
					cy={12.132}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-26"
					className="seat ech-stall-g"
					cx={502.881}
					cy={9.743}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-27"
					className="seat ech-stall-g"
					cx={515.482}
					cy={7.099}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-28"
					className="seat ech-stall-g"
					cx={528.023}
					cy={4.177}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-29"
					className="seat ech-stall-g"
					cx={540.491}
					cy={0.948}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-g-30"
					className="seat ech-stall-g"
					cx={552.88}
					cy={-2.615}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-5"
					className="seat ech-stall-h"
					cx={227.651}
					cy={20.315}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-6"
					className="seat ech-stall-h"
					cx={240.154}
					cy={23.667}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-7"
					className="seat ech-stall-h"
					cx={252.727}
					cy={26.703}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-8"
					className="seat ech-stall-h"
					cx={265.369}
					cy={29.419}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-9"
					className="seat ech-stall-h"
					cx={278.065}
					cy={31.866}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-10"
					className="seat ech-stall-h"
					cx={290.81}
					cy={34.045}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-11"
					className="seat ech-stall-h"
					cx={303.597}
					cy={35.956}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-12"
					className="seat ech-stall-h"
					cx={316.422}
					cy={37.597}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-13"
					className="seat ech-stall-h"
					cx={329.278}
					cy={38.962}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-14"
					className="seat ech-stall-h"
					cx={342.162}
					cy={40.028}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-15"
					className="seat ech-stall-h"
					cx={355.067}
					cy={40.811}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-16"
					className="seat ech-stall-h"
					cx={367.987}
					cy={41.288}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-17"
					className="seat ech-stall-h"
					cx={380.966}
					cy={41.461}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-18"
					className="seat ech-stall-h"
					cx={393.898}
					cy={41.32}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-19"
					className="seat ech-stall-h"
					cx={406.821}
					cy={40.921}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-20"
					className="seat ech-stall-h"
					cx={419.732}
					cy={40.258}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-21"
					className="seat ech-stall-h"
					cx={432.628}
					cy={39.33}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-22"
					className="seat ech-stall-h"
					cx={445.504}
					cy={38.155}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-23"
					className="seat ech-stall-h"
					cx={458.355}
					cy={36.715}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-24"
					className="seat ech-stall-h"
					cx={471.174}
					cy={35.034}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-25"
					className="seat ech-stall-h"
					cx={483.958}
					cy={33.104}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-26"
					className="seat ech-stall-h"
					cx={496.702}
					cy={30.917}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-27"
					className="seat ech-stall-h"
					cx={509.401}
					cy={28.477}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-28"
					className="seat ech-stall-h"
					cx={522.044}
					cy={25.765}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-29"
					className="seat ech-stall-h"
					cx={534.625}
					cy={22.765}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-h-30"
					className="seat ech-stall-h"
					cx={547.137}
					cy={19.443}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-5"
					className="seat ech-stall-j"
					cx={233.07}
					cy={41.591}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-6"
					className="seat ech-stall-j"
					cx={245.575}
					cy={44.877}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-7"
					className="seat ech-stall-j"
					cx={258.159}
					cy={47.787}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-8"
					className="seat ech-stall-j"
					cx={270.812}
					cy={50.367}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-9"
					className="seat ech-stall-j"
					cx={283.52}
					cy={52.654}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-10"
					className="seat ech-stall-j"
					cx={296.278}
					cy={54.648}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-11"
					className="seat ech-stall-j"
					cx={309.077}
					cy={56.351}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-12"
					className="seat ech-stall-j"
					cx={321.911}
					cy={57.766}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-13"
					className="seat ech-stall-j"
					cx={334.773}
					cy={58.895}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-14"
					className="seat ech-stall-j"
					cx={347.656}
					cy={59.743}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-15"
					className="seat ech-stall-j"
					cx={360.554}
					cy={60.318}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-16"
					className="seat ech-stall-j"
					cx={373.486}
					cy={60.585}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-17"
					className="seat ech-stall-j"
					cx={386.424}
					cy={60.576}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-18"
					className="seat ech-stall-j"
					cx={399.332}
					cy={60.311}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-19"
					className="seat ech-stall-j"
					cx={412.234}
					cy={59.803}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-20"
					className="seat ech-stall-j"
					cx={425.123}
					cy={59.033}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-21"
					className="seat ech-stall-j"
					cx={437.996}
					cy={58.027}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-22"
					className="seat ech-stall-j"
					cx={450.845}
					cy={56.773}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-23"
					className="seat ech-stall-j"
					cx={463.668}
					cy={55.259}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-24"
					className="seat ech-stall-j"
					cx={476.46}
					cy={53.5}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-25"
					className="seat ech-stall-j"
					cx={489.214}
					cy={51.486}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-26"
					className="seat ech-stall-j"
					cx={501.925}
					cy={49.21}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-27"
					className="seat ech-stall-j"
					cx={514.585}
					cy={46.667}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-28"
					className="seat ech-stall-j"
					cx={527.179}
					cy={43.796}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-j-29"
					className="seat ech-stall-j"
					cx={539.703}
					cy={40.586}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-5"
					className="seat ech-stall-k"
					cx={227.057}
					cy={60.303}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-6"
					className="seat ech-stall-k"
					cx={239.599}
					cy={63.463}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-7"
					className="seat ech-stall-k"
					cx={252.203}
					cy={66.314}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-8"
					className="seat ech-stall-k"
					cx={264.871}
					cy={68.855}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-9"
					className="seat ech-stall-k"
					cx={277.586}
					cy={71.138}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-10"
					className="seat ech-stall-k"
					cx={290.345}
					cy={73.164}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-11"
					className="seat ech-stall-k"
					cx={303.142}
					cy={74.931}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-12"
					className="seat ech-stall-k"
					cx={315.971}
					cy={76.439}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-13"
					className="seat ech-stall-k"
					cx={328.828}
					cy={77.69}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-14"
					className="seat ech-stall-k"
					cx={341.709}
					cy={78.66}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-15"
					className="seat ech-stall-k"
					cx={354.607}
					cy={79.353}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-16"
					className="seat ech-stall-k"
					cx={367.518}
					cy={79.775}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-17"
					className="seat ech-stall-k"
					cx={380.488}
					cy={79.89}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-18"
					className="seat ech-stall-k"
					cx={393.404}
					cy={79.744}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-19"
					className="seat ech-stall-k"
					cx={406.315}
					cy={79.333}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-20"
					className="seat ech-stall-k"
					cx={419.216}
					cy={78.697}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-21"
					className="seat ech-stall-k"
					cx={432.103}
					cy={77.801}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-22"
					className="seat ech-stall-k"
					cx={444.973}
					cy={76.686}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-23"
					className="seat ech-stall-k"
					cx={457.821}
					cy={75.326}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-24"
					className="seat ech-stall-k"
					cx={470.642}
					cy={73.742}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-25"
					className="seat ech-stall-k"
					cx={483.433}
					cy={71.931}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-26"
					className="seat ech-stall-k"
					cx={496.189}
					cy={69.889}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-27"
					className="seat ech-stall-k"
					cx={508.907}
					cy={67.612}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-28"
					className="seat ech-stall-k"
					cx={521.576}
					cy={65.087}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-29"
					className="seat ech-stall-k"
					cx={534.195}
					cy={62.303}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-k-30"
					className="seat ech-stall-k"
					cx={546.755}
					cy={59.222}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-5"
					className="seat ech-stall-l"
					cx={233.316}
					cy={80.763}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-6"
					className="seat ech-stall-l"
					cx={245.905}
					cy={83.707}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-7"
					className="seat ech-stall-l"
					cx={258.557}
					cy={86.311}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-8"
					className="seat ech-stall-l"
					cx={271.263}
					cy={88.625}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-9"
					className="seat ech-stall-l"
					cx={284.013}
					cy={90.678}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-10"
					className="seat ech-stall-l"
					cx={296.801}
					cy={92.471}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-11"
					className="seat ech-stall-l"
					cx={309.622}
					cy={94.007}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-12"
					className="seat ech-stall-l"
					cx={322.471}
					cy={95.294}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-13"
					className="seat ech-stall-l"
					cx={335.343}
					cy={96.327}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-14"
					className="seat ech-stall-l"
					cx={348.231}
					cy={97.113}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-15"
					className="seat ech-stall-l"
					cx={361.132}
					cy={97.659}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-16"
					className="seat ech-stall-l"
					cx={374.055}
					cy={97.941}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-17"
					className="seat ech-stall-l"
					cx={387.003}
					cy={97.966}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-18"
					className="seat ech-stall-l"
					cx={399.912}
					cy={97.731}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-19"
					className="seat ech-stall-l"
					cx={412.814}
					cy={97.222}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-20"
					className="seat ech-stall-l"
					cx={425.704}
					cy={96.48}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-21"
					className="seat ech-stall-l"
					cx={438.58}
					cy={95.498}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-22"
					className="seat ech-stall-l"
					cx={451.435}
					cy={94.29}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-23"
					className="seat ech-stall-l"
					cx={464.27}
					cy={92.87}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-24"
					className="seat ech-stall-l"
					cx={477.079}
					cy={91.243}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-25"
					className="seat ech-stall-l"
					cx={489.861}
					cy={89.408}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-26"
					className="seat ech-stall-l"
					cx={502.613}
					cy={87.37}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-27"
					className="seat ech-stall-l"
					cx={515.329}
					cy={85.122}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-28"
					className="seat ech-stall-l"
					cx={528.008}
					cy={82.662}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-l-29"
					className="seat ech-stall-l"
					cx={540.646}
					cy={79.961}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-6"
					className="seat ech-stall-m"
					cx={239.92}
					cy={102.052}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-7"
					className="seat ech-stall-m"
					cx={252.511}
					cy={104.772}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-8"
					className="seat ech-stall-m"
					cx={265.158}
					cy={107.133}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-9"
					className="seat ech-stall-m"
					cx={277.852}
					cy={109.218}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-10"
					className="seat ech-stall-m"
					cx={290.586}
					cy={111.03}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-11"
					className="seat ech-stall-m"
					cx={303.355}
					cy={112.584}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-12"
					className="seat ech-stall-m"
					cx={316.151}
					cy={113.893}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-13"
					className="seat ech-stall-m"
					cx={328.97}
					cy={114.963}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-14"
					className="seat ech-stall-m"
					cx={341.805}
					cy={115.8}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-15"
					className="seat ech-stall-m"
					cx={354.653}
					cy={116.407}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-16"
					className="seat ech-stall-m"
					cx={367.51}
					cy={116.774}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-17"
					className="seat ech-stall-m"
					cx={380.412}
					cy={116.917}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-18"
					className="seat ech-stall-m"
					cx={393.274}
					cy={116.828}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-19"
					className="seat ech-stall-m"
					cx={406.133}
					cy={116.551}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-20"
					className="seat ech-stall-m"
					cx={418.986}
					cy={116.049}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-21"
					className="seat ech-stall-m"
					cx={431.829}
					cy={115.34}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-22"
					className="seat ech-stall-m"
					cx={444.658}
					cy={114.415}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-23"
					className="seat ech-stall-m"
					cx={457.47}
					cy={113.254}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-24"
					className="seat ech-stall-m"
					cx={470.258}
					cy={111.861}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-25"
					className="seat ech-stall-m"
					cx={483.016}
					cy={110.225}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-26"
					className="seat ech-stall-m"
					cx={495.737}
					cy={108.319}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-27"
					className="seat ech-stall-m"
					cx={508.415}
					cy={106.133}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-28"
					className="seat ech-stall-m"
					cx={521.039}
					cy={103.646}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-m-29"
					className="seat ech-stall-m"
					cx={533.596}
					cy={100.763}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-5"
					className="seat ech-stall-n"
					cx={233.474}
					cy={118.981}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-6"
					className="seat ech-stall-n"
					cx={246.032}
					cy={121.938}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-7"
					className="seat ech-stall-n"
					cx={258.65}
					cy={124.567}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-8"
					className="seat ech-stall-n"
					cx={271.325}
					cy={126.894}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-9"
					className="seat ech-stall-n"
					cx={284.044}
					cy={128.961}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-10"
					className="seat ech-stall-n"
					cx={296.802}
					cy={130.767}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-11"
					className="seat ech-stall-n"
					cx={309.594}
					cy={132.315}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-12"
					className="seat ech-stall-n"
					cx={322.414}
					cy={133.604}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-13"
					className="seat ech-stall-n"
					cx={335.257}
					cy={134.638}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-14"
					className="seat ech-stall-n"
					cx={348.117}
					cy={135.421}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-15"
					className="seat ech-stall-n"
					cx={360.991}
					cy={135.935}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-16"
					className="seat ech-stall-n"
					cx={373.899}
					cy={136.174}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-17"
					className="seat ech-stall-n"
					cx={386.805}
					cy={136.168}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-18"
					className="seat ech-stall-n"
					cx={399.686}
					cy={135.902}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-19"
					className="seat ech-stall-n"
					cx={412.563}
					cy={135.428}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-20"
					className="seat ech-stall-n"
					cx={425.428}
					cy={134.724}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-21"
					className="seat ech-stall-n"
					cx={438.281}
					cy={133.817}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-22"
					className="seat ech-stall-n"
					cx={451.115}
					cy={132.679}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-23"
					className="seat ech-stall-n"
					cx={463.929}
					cy={131.328}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-24"
					className="seat ech-stall-n"
					cx={476.719}
					cy={129.763}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-25"
					className="seat ech-stall-n"
					cx={489.481}
					cy={127.978}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-26"
					className="seat ech-stall-n"
					cx={502.209}
					cy={125.969}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-27"
					className="seat ech-stall-n"
					cx={514.9}
					cy={123.732}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-28"
					className="seat ech-stall-n"
					cx={527.542}
					cy={121.217}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-n-29"
					className="seat ech-stall-n"
					cx={540.134}
					cy={118.414}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-6"
					className="seat ech-stall-o"
					cx={239.79}
					cy={142.317}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-7"
					className="seat ech-stall-o"
					cx={252.489}
					cy={144.649}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-8"
					className="seat ech-stall-o"
					cx={265.227}
					cy={146.691}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-9"
					className="seat ech-stall-o"
					cx={277.998}
					cy={148.497}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-10"
					className="seat ech-stall-o"
					cx={290.798}
					cy={150.085}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-11"
					className="seat ech-stall-o"
					cx={303.622}
					cy={151.453}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-12"
					className="seat ech-stall-o"
					cx={316.467}
					cy={152.615}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-13"
					className="seat ech-stall-o"
					cx={329.328}
					cy={153.576}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-14"
					className="seat ech-stall-o"
					cx={342.203}
					cy={154.338}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-15"
					className="seat ech-stall-o"
					cx={355.087}
					cy={154.891}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-16"
					className="seat ech-stall-o"
					cx={367.979}
					cy={155.253}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-17"
					className="seat ech-stall-o"
					cx={380.906}
					cy={155.412}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-18"
					className="seat ech-stall-o"
					cx={393.806}
					cy={155.365}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-19"
					className="seat ech-stall-o"
					cx={406.702}
					cy={155.153}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-20"
					className="seat ech-stall-o"
					cx={419.592}
					cy={154.747}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-21"
					className="seat ech-stall-o"
					cx={432.477}
					cy={154.167}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-22"
					className="seat ech-stall-o"
					cx={445.351}
					cy={153.381}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-23"
					className="seat ech-stall-o"
					cx={458.211}
					cy={152.402}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-24"
					className="seat ech-stall-o"
					cx={471.055}
					cy={151.217}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-25"
					className="seat ech-stall-o"
					cx={483.876}
					cy={149.812}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-26"
					className="seat ech-stall-o"
					cx={496.669}
					cy={148.174}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-27"
					className="seat ech-stall-o"
					cx={509.427}
					cy={146.27}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-28"
					className="seat ech-stall-o"
					cx={522.144}
					cy={144.099}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-o-29"
					className="seat ech-stall-o"
					cx={534.811}
					cy={141.561}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-6"
					className="seat ech-stall-p"
					cx={245.009}
					cy={166.191}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-7"
					className="seat ech-stall-p"
					cx={257.878}
					cy={168.094}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-8"
					className="seat ech-stall-p"
					cx={270.772}
					cy={169.725}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-9"
					className="seat ech-stall-p"
					cx={283.689}
					cy={171.15}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-10"
					className="seat ech-stall-p"
					cx={296.627}
					cy={172.365}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-11"
					className="seat ech-stall-p"
					cx={309.58}
					cy={173.398}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-12"
					className="seat ech-stall-p"
					cx={322.546}
					cy={174.257}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-13"
					className="seat ech-stall-p"
					cx={335.521}
					cy={174.949}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-14"
					className="seat ech-stall-p"
					cx={348.504}
					cy={175.47}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-15"
					className="seat ech-stall-p"
					cx={361.493}
					cy={175.825}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-16"
					className="seat ech-stall-p"
					cx={374.499}
					cy={176.026}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-17"
					className="seat ech-stall-p"
					cx={387.508}
					cy={176.06}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-18"
					className="seat ech-stall-p"
					cx={400.5}
					cy={175.948}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-19"
					className="seat ech-stall-p"
					cx={413.492}
					cy={175.673}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-20"
					className="seat ech-stall-p"
					cx={426.479}
					cy={175.245}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-21"
					className="seat ech-stall-p"
					cx={439.459}
					cy={174.647}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-22"
					className="seat ech-stall-p"
					cx={452.43}
					cy={173.867}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-23"
					className="seat ech-stall-p"
					cx={465.388}
					cy={172.902}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-24"
					className="seat ech-stall-p"
					cx={478.331}
					cy={171.739}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-25"
					className="seat ech-stall-p"
					cx={491.254}
					cy={170.364}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-26"
					className="seat ech-stall-p"
					cx={504.149}
					cy={168.741}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-27"
					className="seat ech-stall-p"
					cx={517.007}
					cy={166.838}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-p-28"
					className="seat ech-stall-p"
					cx={529.826}
					cy={164.579}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-6"
					className="seat ech-stall-q"
					cx={239.566}
					cy={183.9}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-7"
					className="seat ech-stall-q"
					cx={252.377}
					cy={185.681}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-8"
					className="seat ech-stall-q"
					cx={265.208}
					cy={187.237}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-9"
					className="seat ech-stall-q"
					cx={278.058}
					cy={188.612}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-10"
					className="seat ech-stall-q"
					cx={290.924}
					cy={189.819}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-11"
					className="seat ech-stall-q"
					cx={303.805}
					cy={190.857}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-12"
					className="seat ech-stall-q"
					cx={316.697}
					cy={191.739}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-13"
					className="seat ech-stall-q"
					cx={329.599}
					cy={192.466}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-14"
					className="seat ech-stall-q"
					cx={342.509}
					cy={193.041}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-15"
					className="seat ech-stall-q"
					cx={355.424}
					cy={193.457}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-16"
					className="seat ech-stall-q"
					cx={368.343}
					cy={193.73}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-17"
					className="seat ech-stall-q"
					cx={381.293}
					cy={193.84}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-18"
					className="seat ech-stall-q"
					cx={394.213}
					cy={193.797}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-19"
					className="seat ech-stall-q"
					cx={407.134}
					cy={193.633}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-20"
					className="seat ech-stall-q"
					cx={420.053}
					cy={193.325}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-21"
					className="seat ech-stall-q"
					cx={432.968}
					cy={192.889}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-22"
					className="seat ech-stall-q"
					cx={445.878}
					cy={192.302}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-23"
					className="seat ech-stall-q"
					cx={458.78}
					cy={191.573}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-24"
					className="seat ech-stall-q"
					cx={471.673}
					cy={190.693}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-25"
					className="seat ech-stall-q"
					cx={484.554}
					cy={189.652}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-26"
					className="seat ech-stall-q"
					cx={497.419}
					cy={188.44}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-27"
					className="seat ech-stall-q"
					cx={510.267}
					cy={187.034}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-28"
					className="seat ech-stall-q"
					cx={523.093}
					cy={185.432}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-q-29"
					className="seat ech-stall-q"
					cx={535.895}
					cy={183.558}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-6"
					className="seat ech-stall-r"
					cx={245.544}
					cy={206.421}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-7"
					className="seat ech-stall-r"
					cx={258.38}
					cy={208.218}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-8"
					className="seat ech-stall-r"
					cx={271.241}
					cy={209.723}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-9"
					className="seat ech-stall-r"
					cx={284.123}
					cy={211.011}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-10"
					className="seat ech-stall-r"
					cx={297.025}
					cy={212.075}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-11"
					className="seat ech-stall-r"
					cx={309.941}
					cy={212.946}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-12"
					className="seat ech-stall-r"
					cx={322.868}
					cy={213.634}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-13"
					className="seat ech-stall-r"
					cx={335.803}
					cy={214.144}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-14"
					className="seat ech-stall-r"
					cx={348.743}
					cy={214.475}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-15"
					className="seat ech-stall-r"
					cx={361.712}
					cy={214.631}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-16"
					className="seat ech-stall-r"
					cx={374.663}
					cy={214.625}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-17"
					className="seat ech-stall-r"
					cx={387.602}
					cy={214.452}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-18"
					className="seat ech-stall-r"
					cx={400.542}
					cy={214.155}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-19"
					className="seat ech-stall-r"
					cx={413.48}
					cy={213.743}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-20"
					className="seat ech-stall-r"
					cx={426.415}
					cy={213.229}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-21"
					className="seat ech-stall-r"
					cx={439.345}
					cy={212.6}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-22"
					className="seat ech-stall-r"
					cx={452.268}
					cy={211.848}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-23"
					className="seat ech-stall-r"
					cx={465.184}
					cy={210.971}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-24"
					className="seat ech-stall-r"
					cx={478.09}
					cy={209.959}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-25"
					className="seat ech-stall-r"
					cx={490.985}
					cy={208.802}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-26"
					className="seat ech-stall-r"
					cx={503.864}
					cy={207.478}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-27"
					className="seat ech-stall-r"
					cx={516.723}
					cy={205.959}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-r-28"
					className="seat ech-stall-r"
					cx={529.564}
					cy={204.194}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-7"
					className="seat ech-stall-s"
					cx={252.259}
					cy={225.977}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-8"
					className="seat ech-stall-s"
					cx={265.128}
					cy={227.639}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-9"
					className="seat ech-stall-s"
					cx={278.014}
					cy={229.064}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-10"
					className="seat ech-stall-s"
					cx={290.919}
					cy={230.301}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-11"
					className="seat ech-stall-s"
					cx={303.84}
					cy={231.347}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-12"
					className="seat ech-stall-s"
					cx={316.773}
					cy={232.227}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-13"
					className="seat ech-stall-s"
					cx={329.715}
					cy={232.943}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-14"
					className="seat ech-stall-s"
					cx={342.666}
					cy={233.496}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-15"
					className="seat ech-stall-s"
					cx={355.623}
					cy={233.886}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-16"
					className="seat ech-stall-s"
					cx={368.591}
					cy={234.123}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-17"
					className="seat ech-stall-s"
					cx={381.575}
					cy={234.187}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-18"
					className="seat ech-stall-s"
					cx={394.533}
					cy={234.095}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-19"
					className="seat ech-stall-s"
					cx={407.494}
					cy={233.892}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-20"
					className="seat ech-stall-s"
					cx={420.453}
					cy={233.552}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-21"
					className="seat ech-stall-s"
					cx={433.408}
					cy={233.086}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-22"
					className="seat ech-stall-s"
					cx={446.357}
					cy={232.477}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-23"
					className="seat ech-stall-s"
					cx={459.298}
					cy={231.71}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-24"
					className="seat ech-stall-s"
					cx={472.228}
					cy={230.769}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-25"
					className="seat ech-stall-s"
					cx={485.143}
					cy={229.637}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-26"
					className="seat ech-stall-s"
					cx={498.04}
					cy={228.299}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-27"
					className="seat ech-stall-s"
					cx={510.909}
					cy={226.691}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-s-28"
					className="seat ech-stall-s"
					cx={523.75}
					cy={224.744}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-10"
					className="seat ech-stall-t"
					cx={297.631}
					cy={250.616}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-11"
					className="seat ech-stall-t"
					cx={310.529}
					cy={251.353}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-12"
					className="seat ech-stall-t"
					cx={323.428}
					cy={251.999}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-13"
					className="seat ech-stall-t"
					cx={336.332}
					cy={252.555}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-14"
					className="seat ech-stall-t"
					cx={349.238}
					cy={253.016}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-15"
					className="seat ech-stall-t"
					cx={362.147}
					cy={253.355}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-16"
					className="seat ech-stall-t"
					cx={375.072}
					cy={253.567}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-17"
					className="seat ech-stall-t"
					cx={388.009}
					cy={253.592}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-18"
					className="seat ech-stall-t"
					cx={400.919}
					cy={253.435}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-19"
					className="seat ech-stall-t"
					cx={413.827}
					cy={253.111}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-20"
					className="seat ech-stall-t"
					cx={426.733}
					cy={252.67}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-21"
					className="seat ech-stall-t"
					cx={439.637}
					cy={252.134}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-22"
					className="seat ech-stall-t"
					cx={452.537}
					cy={251.514}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-23"
					className="seat ech-stall-t"
					cx={465.435}
					cy={250.823}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-t-24"
					className="seat ech-stall-t"
					cx={478.33}
					cy={250.062}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-12"
					className="seat ech-stall-u"
					cx={317.666}
					cy={272.445}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-13"
					className="seat ech-stall-u"
					cx={330.42}
					cy={273.04}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-14"
					className="seat ech-stall-u"
					cx={343.174}
					cy={273.492}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-15"
					className="seat ech-stall-u"
					cx={355.931}
					cy={273.815}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-16"
					className="seat ech-stall-u"
					cx={368.698}
					cy={274.016}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-17"
					className="seat ech-stall-u"
					cx={381.476}
					cy={274.083}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-18"
					className="seat ech-stall-u"
					cx={394.239}
					cy={274.017}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-19"
					className="seat ech-stall-u"
					cx={406.998}
					cy={273.811}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-20"
					className="seat ech-stall-u"
					cx={419.754}
					cy={273.462}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-21"
					className="seat ech-stall-u"
					cx={432.506}
					cy={272.984}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-22"
					className="seat ech-stall-u"
					cx={445.253}
					cy={272.38}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-u-23"
					className="seat ech-stall-u"
					cx={457.998}
					cy={271.642}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-v-13"
					className="seat ech-stall-v"
					cx={336.368}
					cy={293.734}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-v-14"
					className="seat ech-stall-v"
					cx={349.251}
					cy={294.397}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-v-15"
					className="seat ech-stall-v"
					cx={362.137}
					cy={294.929}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-v-16"
					className="seat ech-stall-v"
					cx={375.024}
					cy={295.299}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-v-17"
					className="seat ech-stall-v"
					cx={387.98}
					cy={295.417}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-v-18"
					className="seat ech-stall-v"
					cx={400.867}
					cy={295.195}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-v-19"
					className="seat ech-stall-v"
					cx={413.751}
					cy={294.702}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-v-20"
					className="seat ech-stall-v"
					cx={426.629}
					cy={294.011}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-v-21"
					className="seat ech-stall-v"
					cx={439.502}
					cy={293.154}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-w-15"
					className="seat ech-stall-w"
					cx={356.39}
					cy={315.434}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-w-16"
					className="seat ech-stall-w"
					cx={369.051}
					cy={315.5}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-w-17"
					className="seat ech-stall-w"
					cx={381.71}
					cy={315.567}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-w-18"
					className="seat ech-stall-w"
					cx={394.37}
					cy={315.487}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-w-19"
					className="seat ech-stall-w"
					cx={407.027}
					cy={315.248}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-w-20"
					className="seat ech-stall-w"
					cx={419.684}
					cy={315.009}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-25"
					className="seat ech-stall-aa"
					cx={291.429}
					cy={328.734}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-26"
					className="seat ech-stall-aa"
					cx={302.274}
					cy={337.519}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-27"
					className="seat ech-stall-aa"
					cx={313.925}
					cy={345.208}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-28"
					className="seat ech-stall-aa"
					cx={326.274}
					cy={351.721}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-29"
					className="seat ech-stall-aa"
					cx={339.205}
					cy={356.99}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-30"
					className="seat ech-stall-aa"
					cx={352.594}
					cy={360.96}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-31"
					className="seat ech-stall-aa"
					cx={366.312}
					cy={363.594}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-32"
					className="seat ech-stall-aa"
					cx={380.24}
					cy={364.878}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-33"
					className="seat ech-stall-aa"
					cx={394.454}
					cy={364.831}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-34"
					className="seat ech-stall-aa"
					cx={408.359}
					cy={363.451}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-35"
					className="seat ech-stall-aa"
					cx={422.059}
					cy={360.723}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-36"
					className="seat ech-stall-aa"
					cx={435.421}
					cy={356.663}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-37"
					className="seat ech-stall-aa"
					cx={448.316}
					cy={351.308}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-38"
					className="seat ech-stall-aa"
					cx={460.62}
					cy={344.714}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-39"
					className="seat ech-stall-aa"
					cx={472.218}
					cy={336.949}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-aa-40"
					className="seat ech-stall-aa"
					cx={483.005}
					cy={328.091}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-9"
					className="seat ech-stall-bb"
					cx={183.877}
					cy={134.865}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-10"
					className="seat ech-stall-bb"
					cx={185.634}
					cy={148.185}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-11"
					className="seat ech-stall-bb"
					cx={187.428}
					cy={161.502}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-12"
					className="seat ech-stall-bb"
					cx={189.264}
					cy={174.814}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-13"
					className="seat ech-stall-bb"
					cx={191.145}
					cy={188.12}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-14"
					className="seat ech-stall-bb"
					cx={193.078}
					cy={201.419}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-15"
					className="seat ech-stall-bb"
					cx={195.093}
					cy={214.707}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-16"
					className="seat ech-stall-bb"
					cx={197.209}
					cy={227.982}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-17"
					className="seat ech-stall-bb"
					cx={199.51}
					cy={241.241}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-18"
					className="seat ech-stall-bb"
					cx={202.306}
					cy={254.492}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-19"
					className="seat ech-stall-bb"
					cx={206.487}
					cy={267.205}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-20"
					className="seat ech-stall-bb"
					cx={211.965}
					cy={279.435}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-21"
					className="seat ech-stall-bb"
					cx={218.438}
					cy={291.183}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-22"
					className="seat ech-stall-bb"
					cx={225.721}
					cy={302.46}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-23"
					className="seat ech-stall-bb"
					cx={233.706}
					cy={313.259}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-24"
					className="seat ech-stall-bb"
					cx={271.889}
					cy={342.18}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-25"
					className="seat ech-stall-bb"
					cx={282.648}
					cy={351.21}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-26"
					className="seat ech-stall-bb"
					cx={294.125}
					cy={359.315}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-27"
					className="seat ech-stall-bb"
					cx={306.235}
					cy={366.422}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-28"
					className="seat ech-stall-bb"
					cx={318.882}
					cy={372.534}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-29"
					className="seat ech-stall-bb"
					cx={331.996}
					cy={377.596}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-30"
					className="seat ech-stall-bb"
					cx={345.487}
					cy={381.501}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-31"
					className="seat ech-stall-bb"
					cx={359.256}
					cy={384.305}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-32"
					className="seat ech-stall-bb"
					cx={373.214}
					cy={386.001}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-33"
					className="seat ech-stall-bb"
					cx={387.407}
					cy={386.499}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-34"
					className="seat ech-stall-bb"
					cx={401.519}
					cy={385.889}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-35"
					className="seat ech-stall-bb"
					cx={415.467}
					cy={384.13}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-36"
					className="seat ech-stall-bb"
					cx={429.214}
					cy={381.218}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-37"
					className="seat ech-stall-bb"
					cx={442.679}
					cy={377.226}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-38"
					className="seat ech-stall-bb"
					cx={455.761}
					cy={372.081}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-39"
					className="seat ech-stall-bb"
					cx={468.362}
					cy={365.877}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-40"
					className="seat ech-stall-bb"
					cx={480.426}
					cy={358.687}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-41"
					className="seat ech-stall-bb"
					cx={491.847}
					cy={350.509}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-42"
					className="seat ech-stall-bb"
					cx={502.545}
					cy={341.406}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-43"
					className="seat ech-stall-bb"
					cx={540.819}
					cy={313.261}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-44"
					className="seat ech-stall-bb"
					cx={548.804}
					cy={302.462}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-45"
					className="seat ech-stall-bb"
					cx={556.087}
					cy={291.186}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-46"
					className="seat ech-stall-bb"
					cx={562.56}
					cy={279.438}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-47"
					className="seat ech-stall-bb"
					cx={568.038}
					cy={267.208}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-48"
					className="seat ech-stall-bb"
					cx={572.219}
					cy={254.497}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-49"
					className="seat ech-stall-bb"
					cx={575.017}
					cy={241.242}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-50"
					className="seat ech-stall-bb"
					cx={577.317}
					cy={227.983}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-51"
					className="seat ech-stall-bb"
					cx={579.434}
					cy={214.707}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-52"
					className="seat ech-stall-bb"
					cx={581.448}
					cy={201.419}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-53"
					className="seat ech-stall-bb"
					cx={583.382}
					cy={188.12}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-54"
					className="seat ech-stall-bb"
					cx={585.262}
					cy={174.814}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-55"
					className="seat ech-stall-bb"
					cx={587.099}
					cy={161.502}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-56"
					className="seat ech-stall-bb"
					cx={588.893}
					cy={148.185}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-bb-57"
					className="seat ech-stall-bb"
					cx={590.65}
					cy={134.864}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-6"
					className="seat ech-stall-cc"
					cx={164.338}
					cy={136.671}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-7"
					className="seat ech-stall-cc"
					cx={165.67}
					cy={149.915}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-8"
					className="seat ech-stall-cc"
					cx={167.287}
					cy={163.136}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-9"
					className="seat ech-stall-cc"
					cx={169.075}
					cy={176.338}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-10"
					className="seat ech-stall-cc"
					cx={171.027}
					cy={189.518}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-11"
					className="seat ech-stall-cc"
					cx={173.1}
					cy={202.681}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-12"
					className="seat ech-stall-cc"
					cx={175.289}
					cy={215.826}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-13"
					className="seat ech-stall-cc"
					cx={177.599}
					cy={228.951}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-14"
					className="seat ech-stall-cc"
					cx={180.031}
					cy={242.056}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-15"
					className="seat ech-stall-cc"
					cx={182.627}
					cy={255.136}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-16"
					className="seat ech-stall-cc"
					cx={185.625}
					cy={268.218}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-17"
					className="seat ech-stall-cc"
					cx={189.808}
					cy={280.831}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-18"
					className="seat ech-stall-cc"
					cx={195.238}
					cy={292.967}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-19"
					className="seat ech-stall-cc"
					cx={201.674}
					cy={304.611}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-20"
					className="seat ech-stall-cc"
					cx={208.943}
					cy={315.763}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-21"
					className="seat ech-stall-cc"
					cx={216.978}
					cy={326.383}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-22"
					className="seat ech-stall-cc"
					cx={251.744}
					cy={355.223}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-23"
					className="seat ech-stall-cc"
					cx={262.496}
					cy={364.454}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-24"
					className="seat ech-stall-cc"
					cx={273.887}
					cy={372.881}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-25"
					className="seat ech-stall-cc"
					cx={285.842}
					cy={380.491}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-26"
					className="seat ech-stall-cc"
					cx={298.31}
					cy={387.225}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-27"
					className="seat ech-stall-cc"
					cx={311.232}
					cy={393.047}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-28"
					className="seat ech-stall-cc"
					cx={324.529}
					cy={397.949}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-29"
					className="seat ech-stall-cc"
					cx={338.158}
					cy={401.848}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-30"
					className="seat ech-stall-cc"
					cx={352.018}
					cy={404.831}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-31"
					className="seat ech-stall-cc"
					cx={366.068}
					cy={406.721}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-32"
					className="seat ech-stall-cc"
					cx={380.236}
					cy={407.696}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-33"
					className="seat ech-stall-cc"
					cx={394.577}
					cy={407.631}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-34"
					className="seat ech-stall-cc"
					cx={408.718}
					cy={406.585}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-35"
					className="seat ech-stall-cc"
					cx={422.754}
					cy={404.596}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-36"
					className="seat ech-stall-cc"
					cx={436.593}
					cy={401.517}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-37"
					className="seat ech-stall-cc"
					cx={450.199}
					cy={397.542}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-38"
					className="seat ech-stall-cc"
					cx={463.457}
					cy={392.531}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-39"
					className="seat ech-stall-cc"
					cx={476.345}
					cy={386.634}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-40"
					className="seat ech-stall-cc"
					cx={488.761}
					cy={379.805}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-41"
					className="seat ech-stall-cc"
					cx={500.668}
					cy={372.121}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-42"
					className="seat ech-stall-cc"
					cx={511.999}
					cy={363.613}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-43"
					className="seat ech-stall-cc"
					cx={522.692}
					cy={354.313}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-44"
					className="seat ech-stall-cc"
					cx={557.546}
					cy={326.385}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-45"
					className="seat ech-stall-cc"
					cx={565.583}
					cy={315.765}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-46"
					className="seat ech-stall-cc"
					cx={572.851}
					cy={304.613}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-47"
					className="seat ech-stall-cc"
					cx={579.287}
					cy={292.97}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-48"
					className="seat ech-stall-cc"
					cx={584.716}
					cy={280.835}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-49"
					className="seat ech-stall-cc"
					cx={588.9}
					cy={268.222}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-50"
					className="seat ech-stall-cc"
					cx={591.899}
					cy={255.137}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-51"
					className="seat ech-stall-cc"
					cx={594.495}
					cy={242.057}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-52"
					className="seat ech-stall-cc"
					cx={596.928}
					cy={228.951}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-53"
					className="seat ech-stall-cc"
					cx={599.238}
					cy={215.826}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-54"
					className="seat ech-stall-cc"
					cx={601.427}
					cy={202.681}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-55"
					className="seat ech-stall-cc"
					cx={603.5}
					cy={189.519}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-56"
					className="seat ech-stall-cc"
					cx={605.452}
					cy={176.338}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-57"
					className="seat ech-stall-cc"
					cx={607.239}
					cy={163.137}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-58"
					className="seat ech-stall-cc"
					cx={608.857}
					cy={149.916}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-cc-59"
					className="seat ech-stall-cc"
					cx={610.188}
					cy={136.672}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-4"
					className="seat ech-stall-dd"
					cx={143.564}
					cy={133.663}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-5"
					className="seat ech-stall-dd"
					cx={145.097}
					cy={147.094}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-6"
					className="seat ech-stall-dd"
					cx={146.95}
					cy={160.513}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-7"
					className="seat ech-stall-dd"
					cx={148.99}
					cy={173.91}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-8"
					className="seat ech-stall-dd"
					cx={151.154}
					cy={187.29}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-9"
					className="seat ech-stall-dd"
					cx={153.402}
					cy={200.658}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-10"
					className="seat ech-stall-dd"
					cx={155.715}
					cy={214.015}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-11"
					className="seat ech-stall-dd"
					cx={158.084}
					cy={227.363}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-12"
					className="seat ech-stall-dd"
					cx={160.489}
					cy={240.704}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-13"
					className="seat ech-stall-dd"
					cx={162.919}
					cy={254.04}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-14"
					className="seat ech-stall-dd"
					cx={165.359}
					cy={267.419}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-15"
					className="seat ech-stall-dd"
					cx={168.486}
					cy={280.708}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-16"
					className="seat ech-stall-dd"
					cx={173.284}
					cy={293.304}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-17"
					className="seat ech-stall-dd"
					cx={179.207}
					cy={305.461}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-18"
					className="seat ech-stall-dd"
					cx={185.884}
					cy={317.241}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-19"
					className="seat ech-stall-dd"
					cx={193.124}
					cy={328.693}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-20"
					className="seat ech-stall-dd"
					cx={200.835}
					cy={339.841}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-21"
					className="seat ech-stall-dd"
					cx={232.303}
					cy={370.429}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-22"
					className="seat ech-stall-dd"
					cx={243.016}
					cy={379.747}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-23"
					className="seat ech-stall-dd"
					cx={254.259}
					cy={388.419}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-24"
					className="seat ech-stall-dd"
					cx={266.035}
					cy={396.353}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-25"
					className="seat ech-stall-dd"
					cx={278.305}
					cy={403.501}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-26"
					className="seat ech-stall-dd"
					cx={290.978}
					cy={409.905}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-27"
					className="seat ech-stall-dd"
					cx={304.019}
					cy={415.524}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-28"
					className="seat ech-stall-dd"
					cx={317.42}
					cy={420.229}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-29"
					className="seat ech-stall-dd"
					cx={331.08}
					cy={424.115}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-30"
					className="seat ech-stall-dd"
					cx={344.95}
					cy={427.171}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-31"
					className="seat ech-stall-dd"
					cx={358.99}
					cy={429.328}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-32"
					className="seat ech-stall-dd"
					cx={373.143}
					cy={430.555}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-33"
					className="seat ech-stall-dd"
					cx={387.463}
					cy={430.962}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-34"
					className="seat ech-stall-dd"
					cx={401.703}
					cy={430.478}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-35"
					className="seat ech-stall-dd"
					cx={415.848}
					cy={429.139}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-36"
					className="seat ech-stall-dd"
					cx={429.867}
					cy={426.872}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-37"
					className="seat ech-stall-dd"
					cx={443.72}
					cy={423.736}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-38"
					className="seat ech-stall-dd"
					cx={457.356}
					cy={419.77}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-39"
					className="seat ech-stall-dd"
					cx={470.721}
					cy={414.963}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-40"
					className="seat ech-stall-dd"
					cx={483.721}
					cy={409.251}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-41"
					className="seat ech-stall-dd"
					cx={496.355}
					cy={402.769}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-42"
					className="seat ech-stall-dd"
					cx={508.579}
					cy={395.545}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-43"
					className="seat ech-stall-dd"
					cx={520.295}
					cy={387.521}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-44"
					className="seat ech-stall-dd"
					cx={531.482}
					cy={378.777}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-45"
					className="seat ech-stall-dd"
					cx={542.133}
					cy={369.388}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-46"
					className="seat ech-stall-dd"
					cx={573.691}
					cy={339.843}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-47"
					className="seat ech-stall-dd"
					cx={581.402}
					cy={328.694}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-48"
					className="seat ech-stall-dd"
					cx={588.641}
					cy={317.242}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-49"
					className="seat ech-stall-dd"
					cx={595.318}
					cy={305.463}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-50"
					className="seat ech-stall-dd"
					cx={601.241}
					cy={293.306}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-51"
					className="seat ech-stall-dd"
					cx={606.038}
					cy={280.712}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-52"
					className="seat ech-stall-dd"
					cx={609.168}
					cy={267.419}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-53"
					className="seat ech-stall-dd"
					cx={611.608}
					cy={254.04}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-54"
					className="seat ech-stall-dd"
					cx={614.038}
					cy={240.704}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-55"
					className="seat ech-stall-dd"
					cx={616.443}
					cy={227.363}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-56"
					className="seat ech-stall-dd"
					cx={618.812}
					cy={214.015}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-57"
					className="seat ech-stall-dd"
					cx={621.125}
					cy={200.658}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-58"
					className="seat ech-stall-dd"
					cx={623.373}
					cy={187.291}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-59"
					className="seat ech-stall-dd"
					cx={625.537}
					cy={173.91}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-60"
					className="seat ech-stall-dd"
					cx={627.576}
					cy={160.513}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-61"
					className="seat ech-stall-dd"
					cx={629.429}
					cy={147.095}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-dd-62"
					className="seat ech-stall-dd"
					cx={630.962}
					cy={133.665}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-1"
					className="seat ech-stall-ee"
					cx={124.267}
					cy={138.911}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-2"
					className="seat ech-stall-ee"
					cx={125.343}
					cy={152.094}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-3"
					className="seat ech-stall-ee"
					cx={126.89}
					cy={165.26}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-4"
					className="seat ech-stall-ee"
					cx={128.684}
					cy={178.402}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-5"
					className="seat ech-stall-ee"
					cx={130.668}
					cy={191.519}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-6"
					className="seat ech-stall-ee"
					cx={132.814}
					cy={204.614}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-7"
					className="seat ech-stall-ee"
					cx={135.098}
					cy={217.684}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-8"
					className="seat ech-stall-ee"
					cx={137.514}
					cy={230.733}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-9"
					className="seat ech-stall-ee"
					cx={140.058}
					cy={243.758}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-10"
					className="seat ech-stall-ee"
					cx={142.741}
					cy={256.757}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-11"
					className="seat ech-stall-ee"
					cx={145.602}
					cy={269.724}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-12"
					className="seat ech-stall-ee"
					cx={148.736}
					cy={282.643}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-13"
					className="seat ech-stall-ee"
					cx={152.576}
					cy={295.397}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-14"
					className="seat ech-stall-ee"
					cx={157.576}
					cy={307.642}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-15"
					className="seat ech-stall-ee"
					cx={163.416}
					cy={319.533}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-16"
					className="seat ech-stall-ee"
					cx={169.854}
					cy={331.121}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-17"
					className="seat ech-stall-ee"
					cx={176.769}
					cy={342.439}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-18"
					className="seat ech-stall-ee"
					cx={184.084}
					cy={353.511}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-19"
					className="seat ech-stall-ee"
					cx={211.374}
					cy={384.267}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-20"
					className="seat ech-stall-ee"
					cx={222.084}
					cy={393.725}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-21"
					className="seat ech-stall-ee"
					cx={233.292}
					cy={402.583}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-22"
					className="seat ech-stall-ee"
					cx={244.97}
					cy={410.811}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-23"
					className="seat ech-stall-ee"
					cx={257.085}
					cy={418.38}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-24"
					className="seat ech-stall-ee"
					cx={269.602}
					cy={425.268}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-25"
					className="seat ech-stall-ee"
					cx={282.486}
					cy={431.43}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-26"
					className="seat ech-stall-ee"
					cx={295.723}
					cy={436.829}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-27"
					className="seat ech-stall-ee"
					cx={309.243}
					cy={441.452}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-28"
					className="seat ech-stall-ee"
					cx={322.998}
					cy={445.318}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-29"
					className="seat ech-stall-ee"
					cx={336.947}
					cy={448.415}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-30"
					className="seat ech-stall-ee"
					cx={351.047}
					cy={450.738}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-31"
					className="seat ech-stall-ee"
					cx={365.255}
					cy={452.281}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-32"
					className="seat ech-stall-ee"
					cx={379.553}
					cy={453.007}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-33"
					className="seat ech-stall-ee"
					cx={393.965}
					cy={452.964}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-34"
					className="seat ech-stall-ee"
					cx={408.232}
					cy={452.122}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-35"
					className="seat ech-stall-ee"
					cx={422.43}
					cy={450.494}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-36"
					className="seat ech-stall-ee"
					cx={436.516}
					cy={448.089}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-37"
					className="seat ech-stall-ee"
					cx={450.446}
					cy={444.908}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-38"
					className="seat ech-stall-ee"
					cx={464.178}
					cy={440.959}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-39"
					className="seat ech-stall-ee"
					cx={477.668}
					cy={436.239}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-40"
					className="seat ech-stall-ee"
					cx={490.857}
					cy={430.744}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-41"
					className="seat ech-stall-ee"
					cx={503.698}
					cy={424.484}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-42"
					className="seat ech-stall-ee"
					cx={516.17}
					cy={417.514}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-43"
					className="seat ech-stall-ee"
					cx={528.233}
					cy={409.865}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-44"
					className="seat ech-stall-ee"
					cx={539.855}
					cy={401.557}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-45"
					className="seat ech-stall-ee"
					cx={551.003}
					cy={392.623}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-46"
					className="seat ech-stall-ee"
					cx={561.647}
					cy={383.093}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-47"
					className="seat ech-stall-ee"
					cx={590.442}
					cy={353.512}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-48"
					className="seat ech-stall-ee"
					cx={597.757}
					cy={342.44}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-49"
					className="seat ech-stall-ee"
					cx={604.672}
					cy={331.122}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-50"
					className="seat ech-stall-ee"
					cx={611.11}
					cy={319.534}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-51"
					className="seat ech-stall-ee"
					cx={616.95}
					cy={307.644}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-52"
					className="seat ech-stall-ee"
					cx={621.949}
					cy={295.4}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-53"
					className="seat ech-stall-ee"
					cx={625.791}
					cy={282.645}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-54"
					className="seat ech-stall-ee"
					cx={628.924}
					cy={269.724}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-55"
					className="seat ech-stall-ee"
					cx={631.786}
					cy={256.758}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-56"
					className="seat ech-stall-ee"
					cx={634.468}
					cy={243.758}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-57"
					className="seat ech-stall-ee"
					cx={637.013}
					cy={230.733}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-58"
					className="seat ech-stall-ee"
					cx={639.429}
					cy={217.685}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-59"
					className="seat ech-stall-ee"
					cx={641.713}
					cy={204.614}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-60"
					className="seat ech-stall-ee"
					cx={643.858}
					cy={191.519}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-61"
					className="seat ech-stall-ee"
					cx={645.843}
					cy={178.402}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-62"
					className="seat ech-stall-ee"
					cx={647.637}
					cy={165.261}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-63"
					className="seat ech-stall-ee"
					cx={649.183}
					cy={152.095}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ee-64"
					className="seat ech-stall-ee"
					cx={650.259}
					cy={138.913}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-14"
					className="seat ech-stall-ff"
					cx={146.879}
					cy={331.813}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-15"
					className="seat ech-stall-ff"
					cx={153.417}
					cy={343.492}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-16"
					className="seat ech-stall-ff"
					cx={160.287}
					cy={355.015}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-17"
					className="seat ech-stall-ff"
					cx={167.375}
					cy={366.416}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-18"
					className="seat ech-stall-ff"
					cx={192.464}
					cy={400.404}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-19"
					className="seat ech-stall-ff"
					cx={203.124}
					cy={409.962}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-20"
					className="seat ech-stall-ff"
					cx={214.25}
					cy={418.975}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-21"
					className="seat ech-stall-ff"
					cx={225.82}
					cy={427.414}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-22"
					className="seat ech-stall-ff"
					cx={237.803}
					cy={435.252}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-23"
					className="seat ech-stall-ff"
					cx={250.169}
					cy={442.47}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-24"
					className="seat ech-stall-ff"
					cx={262.886}
					cy={449.048}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-25"
					className="seat ech-stall-ff"
					cx={275.923}
					cy={454.967}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-26"
					className="seat ech-stall-ff"
					cx={289.245}
					cy={460.213}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-27"
					className="seat ech-stall-ff"
					cx={302.819}
					cy={464.77}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-28"
					className="seat ech-stall-ff"
					cx={316.607}
					cy={468.63}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-29"
					className="seat ech-stall-ff"
					cx={330.575}
					cy={471.784}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-30"
					className="seat ech-stall-ff"
					cx={344.684}
					cy={474.226}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-31"
					className="seat ech-stall-ff"
					cx={358.9}
					cy={475.955}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-32"
					className="seat ech-stall-ff"
					cx={373.184}
					cy={476.97}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-33"
					className="seat ech-stall-ff"
					cx={387.606}
					cy={477.331}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-34"
					className="seat ech-stall-ff"
					cx={401.95}
					cy={476.934}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-35"
					className="seat ech-stall-ff"
					cx={416.23}
					cy={475.824}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-36"
					className="seat ech-stall-ff"
					cx={430.435}
					cy={473.996}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-37"
					className="seat ech-stall-ff"
					cx={444.528}
					cy={471.448}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-38"
					className="seat ech-stall-ff"
					cx={458.471}
					cy={468.183}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-39"
					className="seat ech-stall-ff"
					cx={472.229}
					cy={464.21}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-40"
					className="seat ech-stall-ff"
					cx={485.765}
					cy={459.539}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-41"
					className="seat ech-stall-ff"
					cx={499.046}
					cy={454.191}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-42"
					className="seat ech-stall-ff"
					cx={512.044}
					cy={448.182}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-43"
					className="seat ech-stall-ff"
					cx={524.721}
					cy={441.524}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-44"
					className="seat ech-stall-ff"
					cx={537.044}
					cy={434.232}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-45"
					className="seat ech-stall-ff"
					cx={548.98}
					cy={426.322}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-46"
					className="seat ech-stall-ff"
					cx={560.498}
					cy={417.816}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-47"
					className="seat ech-stall-ff"
					cx={571.571}
					cy={408.736}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-48"
					className="seat ech-stall-ff"
					cx={582.169}
					cy={399.109}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-49"
					className="seat ech-stall-ff"
					cx={607.151}
					cy={366.416}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-50"
					className="seat ech-stall-ff"
					cx={614.239}
					cy={355.016}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-51"
					className="seat ech-stall-ff"
					cx={621.109}
					cy={343.493}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-ff-52"
					className="seat ech-stall-ff"
					cx={627.647}
					cy={331.815}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-18"
					className="seat ech-stall-gg"
					cx={188.949}
					cy={429.957}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-19"
					className="seat ech-stall-gg"
					cx={199.403}
					cy={439.231}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-20"
					className="seat ech-stall-gg"
					cx={210.347}
					cy={447.92}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-21"
					className="seat ech-stall-gg"
					cx={221.747}
					cy={456.003}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-22"
					className="seat ech-stall-gg"
					cx={233.566}
					cy={463.463}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-23"
					className="seat ech-stall-gg"
					cx={245.765}
					cy={470.285}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-24"
					className="seat ech-stall-gg"
					cx={258.304}
					cy={476.462}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-25"
					className="seat ech-stall-gg"
					cx={271.143}
					cy={481.991}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-26"
					className="seat ech-stall-gg"
					cx={284.244}
					cy={486.871}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-27"
					className="seat ech-stall-gg"
					cx={297.568}
					cy={491.105}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-28"
					className="seat ech-stall-gg"
					cx={311.08}
					cy={494.7}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-29"
					className="seat ech-stall-gg"
					cx={324.744}
					cy={497.663}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-30"
					className="seat ech-stall-gg"
					cx={338.529}
					cy={500.005}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-31"
					className="seat ech-stall-gg"
					cx={352.404}
					cy={501.735}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-32"
					className="seat ech-stall-gg"
					cx={366.342}
					cy={502.866}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-33"
					className="seat ech-stall-gg"
					cx={380.339}
					cy={503.409}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-34"
					className="seat ech-stall-gg"
					cx={394.414}
					cy={503.385}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-35"
					className="seat ech-stall-gg"
					cx={408.378}
					cy={502.682}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-36"
					className="seat ech-stall-gg"
					cx={422.292}
					cy={501.304}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-37"
					className="seat ech-stall-gg"
					cx={436.12}
					cy={499.246}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-38"
					className="seat ech-stall-gg"
					cx={449.829}
					cy={496.508}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-39"
					className="seat ech-stall-gg"
					cx={463.386}
					cy={493.095}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-40"
					className="seat ech-stall-gg"
					cx={476.755}
					cy={489.016}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-41"
					className="seat ech-stall-gg"
					cx={489.907}
					cy={484.284}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-42"
					className="seat ech-stall-gg"
					cx={502.803}
					cy={478.941}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-43"
					className="seat ech-stall-gg"
					cx={515.552}
					cy={473.189}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-44"
					className="seat ech-stall-gg"
					cx={528.114}
					cy={467.038}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-45"
					className="seat ech-stall-gg"
					cx={540.428}
					cy={460.409}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-46"
					className="seat ech-stall-gg"
					cx={552.426}
					cy={453.227}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-47"
					className="seat ech-stall-gg"
					cx={564.027}
					cy={445.427}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-48"
					className="seat ech-stall-gg"
					cx={575.143}
					cy={436.956}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-gg-49"
					className="seat ech-stall-gg"
					cx={585.68}
					cy={427.783}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-1"
					className="seat ech-stall-esplanadebox"
					cx={149.072}
					cy={-77.787}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-2"
					className="seat ech-stall-esplanadebox"
					cx={156.244}
					cy={-63.379}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-3"
					className="seat ech-stall-esplanadebox"
					cx={161.516}
					cy={-48.205}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-4"
					className="seat ech-stall-esplanadebox"
					cx={164.65}
					cy={-32.286}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-5"
					className="seat ech-stall-esplanadebox"
					cx={166.695}
					cy={-16.255}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-6"
					className="seat ech-stall-esplanadebox"
					cx={168.167}
					cy={-0.172}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-7"
					className="seat ech-stall-esplanadebox"
					cx={169.252}
					cy={15.936}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-8"
					className="seat ech-stall-esplanadebox"
					cx={170.015}
					cy={32.059}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-9"
					className="seat ech-stall-esplanadebox"
					cx={170.536}
					cy={48.19}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-10"
					className="seat ech-stall-esplanadebox"
					cx={170.832}
					cy={64.322}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-11"
					className="seat ech-stall-esplanadebox"
					cx={133.543}
					cy={-62.136}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-12"
					className="seat ech-stall-esplanadebox"
					cx={139.422}
					cy={-47.905}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-13"
					className="seat ech-stall-esplanadebox"
					cx={143.488}
					cy={-33.069}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-14"
					className="seat ech-stall-esplanadebox"
					cx={145.987}
					cy={-17.827}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-15"
					className="seat ech-stall-esplanadebox"
					cx={147.645}
					cy={-2.501}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-16"
					className="seat ech-stall-esplanadebox"
					cx={148.771}
					cy={12.863}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-17"
					className="seat ech-stall-esplanadebox"
					cx={149.518}
					cy={28.245}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-18"
					className="seat ech-stall-esplanadebox"
					cx={149.966}
					cy={43.637}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-19"
					className="seat ech-stall-esplanadebox"
					cx={150.164}
					cy={59.031}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-20"
					className="seat ech-stall-esplanadebox"
					cx={150.132}
					cy={74.45}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-21"
					className="seat ech-stall-esplanadebox"
					cx={126.926}
					cy={-14.966}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-22"
					className="seat ech-stall-esplanadebox"
					cx={128.202}
					cy={-0.343}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-23"
					className="seat ech-stall-esplanadebox"
					cx={129.226}
					cy={14.298}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-esplanadebox-24"
					className="seat ech-stall-esplanadebox"
					cx={130.003}
					cy={28.948}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-25"
					className="seat ech-stall-premierbox"
					cx={603.617}
					cy={64.322}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-26"
					className="seat ech-stall-premierbox"
					cx={603.912}
					cy={48.19}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-27"
					className="seat ech-stall-premierbox"
					cx={604.433}
					cy={32.059}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-28"
					className="seat ech-stall-premierbox"
					cx={605.196}
					cy={15.936}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-29"
					className="seat ech-stall-premierbox"
					cx={606.281}
					cy={-0.172}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-30"
					className="seat ech-stall-premierbox"
					cx={607.753}
					cy={-16.255}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-31"
					className="seat ech-stall-premierbox"
					cx={609.798}
					cy={-32.286}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-32"
					className="seat ech-stall-premierbox"
					cx={612.933}
					cy={-48.205}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-33"
					className="seat ech-stall-premierbox"
					cx={618.204}
					cy={-63.379}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-34"
					className="seat ech-stall-premierbox"
					cx={625.376}
					cy={-77.787}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-35"
					className="seat ech-stall-premierbox"
					cx={624.316}
					cy={74.45}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-36"
					className="seat ech-stall-premierbox"
					cx={624.284}
					cy={59.031}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-37"
					className="seat ech-stall-premierbox"
					cx={624.482}
					cy={43.637}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-38"
					className="seat ech-stall-premierbox"
					cx={624.93}
					cy={28.245}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-39"
					className="seat ech-stall-premierbox"
					cx={625.677}
					cy={12.863}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-40"
					className="seat ech-stall-premierbox"
					cx={626.803}
					cy={-2.501}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-41"
					className="seat ech-stall-premierbox"
					cx={628.461}
					cy={-17.827}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-42"
					className="seat ech-stall-premierbox"
					cx={630.96}
					cy={-33.069}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-43"
					className="seat ech-stall-premierbox"
					cx={635.026}
					cy={-47.905}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-44"
					className="seat ech-stall-premierbox"
					cx={640.905}
					cy={-62.136}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-45"
					className="seat ech-stall-premierbox"
					cx={644.446}
					cy={28.948}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-46"
					className="seat ech-stall-premierbox"
					cx={645.222}
					cy={14.298}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-47"
					className="seat ech-stall-premierbox"
					cx={646.246}
					cy={-0.343}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					id="ech-stalls-premierbox-48"
					className="seat ech-stall-premierbox"
					cx={647.522}
					cy={-14.966}
					r={5}
					fill="white"
					stroke="#000"
					strokeWidth={0.265}
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
			</g>
		</svg>
	);
};

export default Stalls;
