/** @format */

import React from 'react';

const Loading: React.FC = () => (
	<div className="loading">
		<div className="lds-grid">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
);

export default Loading;
