/** @format */

import * as React from 'react';

interface Circle1Props {
	width: number;
	height: number;
}

const Circle1: React.FC<Circle1Props> = ({ width, height }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 505.749 530.324"
		>
			<g
				transform="translate(30.815 20.708)"
				fill="none"
				stroke="#000"
				strokeWidth={0.265}
			>
				<circle
					cx={-3.518}
					cy={-15.576}
					r={5}
					id="ech-circle1-balcony-1"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-1.847}
					cy={-1.271}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={0.483}
					cy={13.131}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={2.145}
					cy={27.938}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={4.656}
					cy={42.092}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={6.319}
					cy={56.898}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-25.683}
					cy={103.186}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-24.012}
					cy={117.491}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-22.061}
					cy={131.844}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-20.39}
					cy={146.149}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-18.06}
					cy={160.552}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-16.398}
					cy={175.359}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-13.887}
					cy={189.512}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-12.224}
					cy={204.319}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-4.595}
					cy={267.586}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-3.265}
					cy={280.997}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-1.821}
					cy={294.361}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-0.278}
					cy={307.832}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={1.761}
					cy={321.188}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={4.792}
					cy={334.309}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={8.82}
					cy={347.159}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={13.816}
					cy={359.661}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={430.601}
					cy={359.662}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={435.597}
					cy={347.159}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={439.625}
					cy={334.31}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={442.655}
					cy={321.189}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={444.695}
					cy={307.832}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={446.238}
					cy={294.361}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={447.682}
					cy={280.996}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={449.012}
					cy={267.586}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={456.342}
					cy={204.319}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={458.005}
					cy={189.512}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={460.516}
					cy={175.359}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={462.178}
					cy={160.552}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={464.508}
					cy={146.149}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={466.179}
					cy={131.844}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={468.13}
					cy={117.491}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={469.801}
					cy={103.186}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={437.8}
					cy={56.898}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={439.462}
					cy={42.092}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={441.973}
					cy={27.938}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={443.635}
					cy={13.131}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={445.965}
					cy={-1.271}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={447.636}
					cy={-15.576}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={7.923}
					cy={89.884}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={9.594}
					cy={103.186}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={11.924}
					cy={116.085}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={13.587}
					cy={129.387}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={16.097}
					cy={142.538}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={17.76}
					cy={155.84}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={19.646}
					cy={169.167}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={21.308}
					cy={182.469}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={23.819}
					cy={195.62}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={25.482}
					cy={208.922}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={23.567}
					cy={241.897}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={24.732}
					cy={253.648}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={25.897}
					cy={265.398}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={27.062}
					cy={277.147}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={28.311}
					cy={288.858}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={29.648}
					cy={300.634}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={31.237}
					cy={312.397}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={33.676}
					cy={323.939}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={37.046}
					cy={335.244}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={41.315}
					cy={346.238}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={46.45}
					cy={356.856}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={52.402}
					cy={367.041}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={59.12}
					cy={376.734}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={66.546}
					cy={385.893}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={94.921}
					cy={412.257}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={104.422}
					cy={419.017}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={114.305}
					cy={425.206}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={124.556}
					cy={430.764}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={135.101}
					cy={435.745}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={145.95}
					cy={440.027}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={157.024}
					cy={443.686}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={168.284}
					cy={446.735}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={179.707}
					cy={449.108}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={191.242}
					cy={450.861}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={202.85}
					cy={452.037}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={214.501}
					cy={452.656}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={226.264}
					cy={452.725}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={237.923}
					cy={452.246}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={249.542}
					cy={451.183}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={261.095}
					cy={449.559}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={272.553}
					cy={447.359}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={283.862}
					cy={444.498}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={294.995}
					cy={441.02}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={305.926}
					cy={436.955}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={316.577}
					cy={432.198}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={326.944}
					cy={426.859}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={336.968}
					cy={420.899}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={346.61}
					cy={414.341}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={377.871}
					cy={385.893}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={385.297}
					cy={376.735}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={392.015}
					cy={367.041}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={397.967}
					cy={356.856}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={403.102}
					cy={346.238}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={407.373}
					cy={335.244}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={410.741}
					cy={323.94}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={413.18}
					cy={312.396}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={414.769}
					cy={300.636}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={416.106}
					cy={288.857}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={417.355}
					cy={277.148}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={418.52}
					cy={265.397}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={419.685}
					cy={253.648}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={420.85}
					cy={241.897}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={418.637}
					cy={208.922}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={420.299}
					cy={195.62}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={422.81}
					cy={182.469}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={424.472}
					cy={169.167}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={426.358}
					cy={155.84}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={428.021}
					cy={142.538}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={430.532}
					cy={129.387}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={432.194}
					cy={116.085}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={434.524}
					cy={103.186}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={436.195}
					cy={89.884}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={81.653}
					cy={423.577}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={90.795}
					cy={430.391}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={100.313}
					cy={436.678}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={110.145}
					cy={442.455}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={120.267}
					cy={447.708}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={130.653}
					cy={452.42}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={141.29}
					cy={456.542}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={152.138}
					cy={460.069}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={163.151}
					cy={463.042}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={174.298}
					cy={465.468}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={185.551}
					cy={467.352}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={196.883}
					cy={468.678}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={208.267}
					cy={469.493}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={219.72}
					cy={469.823}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={231.168}
					cy={469.638}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={242.559}
					cy={468.983}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={253.91}
					cy={467.831}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={265.199}
					cy={466.152}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={276.385}
					cy={463.924}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={287.449}
					cy={461.143}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={298.364}
					cy={457.831}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={309.103}
					cy={453.986}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={319.627}
					cy={449.583}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={329.883}
					cy={444.592}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={339.866}
					cy={439.079}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={349.549}
					cy={433.054}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={358.894}
					cy={426.512}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={64.966}
					cy={431.325}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={73.751}
					cy={438.795}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={83.17}
					cy={445.615}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={92.913}
					cy={451.962}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={102.962}
					cy={457.814}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={113.292}
					cy={463.154}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={123.88}
					cy={467.967}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={134.704}
					cy={472.214}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={145.741}
					cy={475.9}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={156.946}
					cy={479.025}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={168.287}
					cy={481.612}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={179.736}
					cy={483.668}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={191.268}
					cy={485.204}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={202.857}
					cy={486.236}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={214.481}
					cy={486.783}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={226.197}
					cy={486.849}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={237.825}
					cy={486.438}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={249.424}
					cy={485.541}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={260.972}
					cy={484.131}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={272.445}
					cy={482.204}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={283.818}
					cy={479.764}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={295.067}
					cy={476.806}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={306.167}
					cy={473.326}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={317.087}
					cy={469.309}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={327.781}
					cy={464.744}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={338.23}
					cy={459.636}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={348.412}
					cy={454.016}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={358.3}
					cy={447.896}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={367.868}
					cy={441.286}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={377.161}
					cy={434.202}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={55.272}
					cy={446.04}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={63.939}
					cy={452.983}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={72.934}
					cy={459.512}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={82.204}
					cy={465.643}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={91.737}
					cy={471.358}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={101.516}
					cy={476.639}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={111.524}
					cy={481.474}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={121.74}
					cy={485.853}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={132.143}
					cy={489.766}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={142.712}
					cy={493.211}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={153.422}
					cy={496.185}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={164.253}
					cy={498.691}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={175.18}
					cy={500.734}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={186.184}
					cy={502.321}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={197.243}
					cy={503.463}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={208.339}
					cy={504.173}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={219.497}
					cy={504.483}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={230.65}
					cy={504.341}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={241.755}
					cy={503.791}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={252.829}
					cy={502.806}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={263.855}
					cy={501.378}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={274.813}
					cy={499.504}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={285.684}
					cy={497.18}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={296.448}
					cy={494.401}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={307.083}
					cy={491.169}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={317.57}
					cy={487.482}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={327.886}
					cy={483.345}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={338.011}
					cy={478.76}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={347.924}
					cy={473.733}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={357.604}
					cy={468.272}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={367.032}
					cy={462.385}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={376.187}
					cy={456.084}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={385.09}
					cy={449.441}
					r={5}
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
			</g>
		</svg>
	);
};

export default Circle1;
