/** @format */

import React from 'react';

import { gql, useQuery } from '@apollo/client';
import Loading from '../Loading';
import { EVENT } from '../../constants';
import Stalls from '../Stalls';
import Circle1 from '../Circle1';
import Circle2 from '../Circle2';
import Circle3 from '../Circle3';
import Gallery from '../Gallery/Gallery';

const GET_SECTION = gql`
	query GetSection($event: Int!, $section: String!) {
		seatSummaryBySections(event_id: $event, section: $section) {
			section
			booked {
				row
				numbers
			}
			blocked {
				row
				numbers
			}
			hold {
				row
				numbers
			}
		}
	}
`;
interface SeatsProps {
	section: string;
	seats: (props: any) => React.ReactNode;
}

const Seats: React.FC<SeatsProps> = ({ section, seats }) => {
	const { loading, error, data } = useQuery(GET_SECTION, {
		variables: { event: EVENT, section: section },
	});

	if (loading) return <Loading />;

	if (error) {
		console.log(error);
	}

	console.log('data', data);

	const width = window.innerWidth * 0.9;
	const height = window.innerHeight * 0.7;

	return (
		<div className="seats-wrapper">
			{/* {seats({
				width: window.innerWidth * 0.9,
				height: window.innerHeight * 0.7,
				section: section,
				event: EVENT,
			})} */}
			{section === 'stalls' && <Stalls width={width} height={height} />}
			{section === 'circle1' && <Circle1 width={width} height={height} />}
			{section === 'circle2' && <Circle2 width={width} height={height} />}
			{section === 'circle3' && <Circle3 width={width} height={height} />}
			{section === 'gallery' && <Gallery width={width} height={height} />}
		</div>
	);
};

export default Seats;
