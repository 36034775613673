/** @format */

import * as React from 'react';

function Circle2(props: any) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={1948.282}
			height={2507.68}
			viewBox="0 0 515.483 663.49"
			{...props}
		>
			<g
				transform="translate(-112.853 -51.986)"
				fill="none"
				stroke="#000"
				strokeWidth={0.265}
			>
				<circle
					cx={117.985}
					cy={57.119}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={124.597}
					cy={71.793}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={130.557}
					cy={86.446}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={131.069}
					cy={100.621}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={129.094}
					cy={114.375}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={129.137}
					cy={129.63}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={129.532}
					cy={144.799}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={129.641}
					cy={159.972}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={129.602}
					cy={175.142}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={129.599}
					cy={190.312}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={129.843}
					cy={205.478}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={130.433}
					cy={220.674}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={130.997}
					cy={235.836}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={131.562}
					cy={251.003}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={132.164}
					cy={266.17}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={132.808}
					cy={281.335}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={133.505}
					cy={296.498}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={134.254}
					cy={311.658}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={135.069}
					cy={326.815}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={135.951}
					cy={341.969}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={136.916}
					cy={357.116}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={137.971}
					cy={372.258}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={139.131}
					cy={387.392}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={140.423}
					cy={402.515}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={141.863}
					cy={417.624}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={143.487}
					cy={432.713}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={145.342}
					cy={447.774}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={147.503}
					cy={462.792}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={157.495}
					cy={492.904}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={162.364}
					cy={506.775}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={168.244}
					cy={520.208}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={175.132}
					cy={533.154}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={183.028}
					cy={545.501}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={191.852}
					cy={557.206}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={201.581}
					cy={568.169}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={212.111}
					cy={578.367}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={223.374}
					cy={587.752}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={235.281}
					cy={596.31}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={247.743}
					cy={604.042}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={260.676}
					cy={610.962}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={274.007}
					cy={617.09}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={287.675}
					cy={622.419}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={301.602}
					cy={627.033}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={315.748}
					cy={630.923}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={330.073}
					cy={634.086}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={344.538}
					cy={636.504}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={359.05}
					cy={638.089}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={373.988}
					cy={638.391}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={388.575}
					cy={637.386}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={403.09}
					cy={635.342}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={417.487}
					cy={632.528}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={431.725}
					cy={628.985}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={445.761}
					cy={624.716}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={459.562}
					cy={619.733}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={473.059}
					cy={613.988}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={486.195}
					cy={607.459}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={498.892}
					cy={600.119}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={511.074}
					cy={591.951}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={522.655}
					cy={582.958}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={533.541}
					cy={573.142}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={543.67}
					cy={562.546}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={552.929}
					cy={551.182}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={561.274}
					cy={539.133}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={568.654}
					cy={526.469}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={575.012}
					cy={513.253}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={580.368}
					cy={499.601}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={593.686}
					cy={462.792}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={595.847}
					cy={447.774}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={597.702}
					cy={432.713}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={599.326}
					cy={417.623}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={600.766}
					cy={402.515}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={602.058}
					cy={387.392}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={603.218}
					cy={372.258}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={604.273}
					cy={357.116}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={605.238}
					cy={341.968}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={606.121}
					cy={326.815}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={606.935}
					cy={311.658}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={607.684}
					cy={296.498}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={608.381}
					cy={281.335}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={609.025}
					cy={266.17}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={609.627}
					cy={251.003}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={610.193}
					cy={235.836}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={610.757}
					cy={220.674}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={611.346}
					cy={205.478}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={611.59}
					cy={190.311}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={611.587}
					cy={175.142}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={611.548}
					cy={159.971}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={611.658}
					cy={144.799}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={612.052}
					cy={129.63}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={612.096}
					cy={114.374}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={610.121}
					cy={100.621}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={610.632}
					cy={86.446}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={616.593}
					cy={71.793}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={623.204}
					cy={57.119}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={146.132}
					cy={514.517}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={152.054}
					cy={528.087}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={158.963}
					cy={541.149}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={166.791}
					cy={553.68}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={175.505}
					cy={565.607}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={185.069}
					cy={576.87}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={195.408}
					cy={587.424}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={206.449}
					cy={597.242}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={218.122}
					cy={606.303}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={230.367}
					cy={614.579}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={243.103}
					cy={622.081}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={256.253}
					cy={628.834}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={269.777}
					cy={634.81}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={283.59}
					cy={640.083}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={297.658}
					cy={644.626}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={311.928}
					cy={648.494}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={326.366}
					cy={651.675}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={340.939}
					cy={654.152}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={355.595}
					cy={655.865}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={370.526}
					cy={656.465}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={385.318}
					cy={655.792}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={399.975}
					cy={654.032}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={414.539}
					cy={651.513}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={428.971}
					cy={648.293}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={443.228}
					cy={644.381}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={457.282}
					cy={639.789}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={471.081}
					cy={634.485}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={484.584}
					cy={628.464}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={497.713}
					cy={621.67}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={510.419}
					cy={614.117}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={522.636}
					cy={605.802}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={534.276}
					cy={596.697}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={545.274}
					cy={586.831}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={555.568}
					cy={576.234}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={565.088}
					cy={564.935}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={573.749}
					cy={552.968}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={581.525}
					cy={540.405}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={588.384}
					cy={527.317}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={594.496}
					cy={513.81}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={143.276}
					cy={549.886}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={150.97}
					cy={562.426}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={159.517}
					cy={574.364}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={168.829}
					cy={585.718}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={178.867}
					cy={596.433}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={189.569}
					cy={606.489}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={200.882}
					cy={615.852}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={212.739}
					cy={624.519}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={225.09}
					cy={632.469}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={237.865}
					cy={639.719}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={251.023}
					cy={646.253}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={264.501}
					cy={652.099}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={278.25}
					cy={657.288}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={292.237}
					cy={661.777}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={306.408}
					cy={665.656}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={320.744}
					cy={668.889}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={335.204}
					cy={671.473}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={349.759}
					cy={673.374}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={364.407}
					cy={674.385}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={379.303}
					cy={674.247}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={393.882}
					cy={672.975}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={408.419}
					cy={670.898}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={422.85}
					cy={668.148}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={437.148}
					cy={664.766}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={451.281}
					cy={660.749}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={465.204}
					cy={656.053}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={478.897}
					cy={650.734}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={492.305}
					cy={644.72}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={505.366}
					cy={638.001}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={518.051}
					cy={630.592}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={530.278}
					cy={622.456}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={542.016}
					cy={613.628}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={553.174}
					cy={604.081}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={563.72}
					cy={593.864}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={573.584}
					cy={582.988}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={582.714}
					cy={571.488}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={591.061}
					cy={559.409}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={598.589}
					cy={546.801}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={141.977}
					cy={580.986}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={151.028}
					cy={592.587}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={160.767}
					cy={603.614}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={171.153}
					cy={614.035}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={182.128}
					cy={623.835}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={193.66}
					cy={632.976}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={205.676}
					cy={641.47}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={218.142}
					cy={649.291}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={231.007}
					cy={656.441}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={244.214}
					cy={662.938}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={257.726}
					cy={668.772}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={271.495}
					cy={673.979}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={285.492}
					cy={678.536}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={299.671}
					cy={682.509}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={314.006}
					cy={685.842}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={328.466}
					cy={688.588}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={343.028}
					cy={690.707}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={357.617}
					cy={692.11}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={372.546}
					cy={692.44}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={387.237}
					cy={691.742}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={401.843}
					cy={690.082}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={416.374}
					cy={687.759}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={430.797}
					cy={684.82}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={445.083}
					cy={681.255}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={459.209}
					cy={677.112}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={473.127}
					cy={672.318}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={486.824}
					cy={666.932}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={500.242}
					cy={660.873}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={513.336}
					cy={654.159}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={526.071}
					cy={646.783}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={538.403}
					cy={638.748}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={550.261}
					cy={630.037}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={561.61}
					cy={620.672}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={572.403}
					cy={610.672}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={582.579}
					cy={600.046}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={592.095}
					cy={588.826}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={600.907}
					cy={577.045}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={130.552}
					cy={595.987}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={139.981}
					cy={607.407}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={149.943}
					cy={618.354}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={160.499}
					cy={628.728}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={171.619}
					cy={638.498}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={183.244}
					cy={647.661}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={195.333}
					cy={656.204}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={207.848}
					cy={664.108}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={220.743}
					cy={671.381}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={233.978}
					cy={678.024}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={247.508}
					cy={684.04}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={261.301}
					cy={689.416}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={275.306}
					cy={694.224}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={289.51}
					cy={698.406}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={303.871}
					cy={702.025}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={318.365}
					cy={705.043}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={332.966}
					cy={707.492}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={347.65}
					cy={709.319}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={362.395}
					cy={710.337}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={377.398}
					cy={710.344}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={392.128}
					cy={709.349}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={406.813}
					cy={707.534}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={421.416}
					cy={705.097}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={435.912}
					cy={702.093}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={450.275}
					cy={698.48}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={464.484}
					cy={694.315}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={478.493}
					cy={689.515}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={492.289}
					cy={684.152}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={505.825}
					cy={678.148}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={519.065}
					cy={671.518}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={531.969}
					cy={664.256}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={544.492}
					cy={656.365}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={556.589}
					cy={647.835}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={568.222}
					cy={638.683}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={579.353}
					cy={628.925}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={589.922}
					cy={618.563}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={599.893}
					cy={607.626}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={609.242}
					cy={596.139}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
			</g>
		</svg>
	);
}

export default Circle2;
