/** @format */

import React from 'react';
import './App.css';
import { client } from './ApolloClient/client';
import { ApolloProvider } from '@apollo/client';
import { sections } from './constants';
import Seats from './components/Seats';
import { Section } from './interfaces';

function App() {
	const [selected, setSelected] = React.useState<Section | null>(null);

	return (
		<ApolloProvider client={client}>
			<div className="App">
				<div className="sections">
					{sections.map((section, index) => (
						<button
							key={index}
							className={`section-btn ${
								section === selected ? 'selected' : ''
							}`}
							onClick={() => setSelected(sections[index])}
						>
							{section.name}
						</button>
					))}
				</div>
				{selected !== null && (
					<Seats section={selected.section} seats={selected.seats} />
				)}
			</div>
		</ApolloProvider>
	);
}

export default App;
