/** @format */

import * as React from 'react';

function Circle3(props: any) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={1818.068}
			height={1051.352}
			viewBox="0 0 481.031 278.17"
			{...props}
		>
			<g
				transform="translate(134.682 -9.082)"
				fill="none"
				stroke="#000"
				strokeWidth={0.265}
			>
				<circle
					cx={-109.333}
					cy={14.681}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-106.767}
					cy={27.996}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-104.182}
					cy={41.531}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-100.351}
					cy={54.487}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-94.669}
					cy={66.732}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-87.484}
					cy={78.176}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-79.066}
					cy={88.761}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-69.641}
					cy={98.475}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-59.384}
					cy={107.315}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-48.437}
					cy={115.293}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-36.915}
					cy={122.42}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-24.899}
					cy={128.708}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-12.544}
					cy={134.331}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-0.001}
					cy={139.472}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={12.726}
					cy={144.137}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={25.632}
					cy={148.28}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={38.697}
					cy={151.889}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={51.908}
					cy={154.92}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={65.239}
					cy={157.359}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={78.669}
					cy={159.122}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={92.166}
					cy={160.103}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={105.819}
					cy={160.324}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={119.479}
					cy={160.147}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={132.974}
					cy={159.129}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={146.4}
					cy={157.334}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={159.726}
					cy={154.869}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={172.93}
					cy={151.808}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={185.99}
					cy={148.178}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={198.887}
					cy={144.008}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={211.607}
					cy={139.324}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={224.141}
					cy={134.16}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={236.486}
					cy={128.516}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={248.489}
					cy={122.207}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={260.017}
					cy={115.087}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={270.985}
					cy={107.137}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={281.277}
					cy={98.339}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={290.74}
					cy={88.663}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={299.187}
					cy={78.105}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={306.343}
					cy={66.651}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={311.891}
					cy={54.359}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={315.413}
					cy={41.318}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={317.286}
					cy={27.671}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={318.954}
					cy={14.214}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-121.777}
					cy={46.79}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-117.329}
					cy={59.629}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-111.241}
					cy={71.695}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-103.926}
					cy={83.087}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-95.701}
					cy={93.861}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-86.738}
					cy={104.036}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-76.962}
					cy={113.426}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-66.481}
					cy={122.027}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-55.402}
					cy={129.851}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-43.809}
					cy={136.897}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-31.766}
					cy={143.158}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-19.404}
					cy={148.794}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-6.859}
					cy={153.973}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={5.866}
					cy={158.686}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={18.757}
					cy={162.92}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={31.809}
					cy={166.633}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={45.001}
					cy={169.811}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={58.313}
					cy={172.425}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={71.73}
					cy={174.431}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={85.221}
					cy={175.748}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={98.821}
					cy={176.275}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={112.499}
					cy={176.318}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={126.081}
					cy={175.799}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={139.572}
					cy={174.469}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={152.987}
					cy={172.451}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={166.296}
					cy={169.824}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={179.486}
					cy={166.637}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={192.534}
					cy={162.913}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={205.423}
					cy={158.67}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={218.145}
					cy={153.949}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={230.685}
					cy={148.76}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={243.042}
					cy={143.115}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={255.086}
					cy={136.856}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={266.695}
					cy={129.836}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={277.804}
					cy={122.055}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={288.33}
					cy={113.506}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={298.158}
					cy={104.172}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={307.171}
					cy={94.044}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={315.391}
					cy={83.269}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={322.632}
					cy={71.832}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={328.604}
					cy={59.713}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={332.906}
					cy={46.833}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-126.313}
					cy={79.587}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-119.153}
					cy={90.974}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-111.143}
					cy={101.792}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-102.456}
					cy={112.085}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-93.052}
					cy={121.72}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-82.973}
					cy={130.65}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-72.293}
					cy={138.855}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-61.118}
					cy={146.377}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-49.486}
					cy={153.171}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-37.466}
					cy={159.28}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-25.169}
					cy={164.821}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-12.702}
					cy={169.941}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-0.066}
					cy={174.621}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={12.729}
					cy={178.848}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={25.671}
					cy={182.593}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={38.751}
					cy={185.836}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={51.948}
					cy={188.558}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={65.244}
					cy={190.721}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={78.623}
					cy={192.274}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={92.055}
					cy={193.125}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={105.615}
					cy={193.323}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={119.182}
					cy={193.189}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={132.613}
					cy={192.325}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={145.991}
					cy={190.764}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={159.286}
					cy={188.592}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={172.481}
					cy={185.864}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={185.56}
					cy={182.612}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={198.5}
					cy={178.86}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={211.292}
					cy={174.627}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={223.926}
					cy={169.94}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={236.391}
					cy={164.813}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={248.683}
					cy={159.267}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={260.704}
					cy={153.16}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={272.349}
					cy={146.388}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={283.55}
					cy={138.904}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={294.267}
					cy={130.746}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={304.394}
					cy={121.87}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={313.851}
					cy={112.288}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={322.581}
					cy={102.034}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={330.587}
					cy={91.215}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={337.692}
					cy={79.796}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-129.55}
					cy={107.09}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-120.538}
					cy={117.215}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-111.491}
					cy={127.321}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-101.811}
					cy={136.797}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-91.507}
					cy={145.593}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-80.687}
					cy={153.749}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-69.392}
					cy={161.237}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-57.683}
					cy={168.06}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-45.606}
					cy={174.229}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-33.255}
					cy={179.842}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-20.739}
					cy={185.046}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-8.055}
					cy={189.829}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={4.788}
					cy={194.172}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={17.776}
					cy={198.053}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={30.897}
					cy={201.457}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={44.136}
					cy={204.365}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={57.475}
					cy={206.753}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={70.905}
					cy={208.571}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={84.397}
					cy={209.771}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={97.976}
					cy={210.266}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={111.623}
					cy={210.329}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={125.2}
					cy={209.926}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={138.698}
					cy={208.799}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={152.136}
					cy={207.045}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={165.488}
					cy={204.713}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={178.738}
					cy={201.864}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={191.871}
					cy={198.51}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={204.873}
					cy={194.677}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={217.73}
					cy={190.384}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={230.432}
					cy={185.649}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={242.969}
					cy={180.488}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={255.34}
					cy={174.92}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={267.458}
					cy={168.835}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={279.219}
					cy={162.1}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={290.592}
					cy={154.732}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={301.506}
					cy={146.7}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={311.908}
					cy={138.02}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={321.729}
					cy={128.689}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={330.877}
					cy={118.681}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={339.839}
					cy={108.512}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-128.696}
					cy={137.111}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-118.087}
					cy={145.554}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-107.319}
					cy={153.794}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-96.399}
					cy={161.829}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-85.32}
					cy={169.64}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-74.061}
					cy={177.18}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-62.57}
					cy={184.328}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-50.616}
					cy={190.688}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-38.203}
					cy={196.224}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-25.664}
					cy={201.378}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-12.966}
					cy={206.125}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-0.119}
					cy={210.45}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={12.869}
					cy={214.334}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={25.985}
					cy={217.761}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={39.214}
					cy={220.713}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={52.542}
					cy={223.176}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={65.958}
					cy={225.105}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={79.438}
					cy={226.462}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={92.977}
					cy={227.17}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={106.606}
					cy={227.33}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={120.231}
					cy={227.176}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={133.751}
					cy={226.356}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={147.223}
					cy={224.906}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={160.626}
					cy={222.886}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={173.94}
					cy={220.346}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={187.154}
					cy={217.316}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={200.25}
					cy={213.814}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={213.216}
					cy={209.859}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={226.04}
					cy={205.466}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={238.712}
					cy={200.654}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={251.224}
					cy={195.436}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={263.599}
					cy={189.81}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={275.49}
					cy={183.354}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={286.971}
					cy={176.179}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={298.211}
					cy={168.607}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={309.245}
					cy={160.732}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={320.09}
					cy={152.597}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={330.751}
					cy={144.22}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={341.216}
					cy={135.6}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-117.495}
					cy={171.814}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-106.074}
					cy={179.055}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-94.512}
					cy={186.074}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-82.828}
					cy={192.884}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-71.001}
					cy={199.428}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-58.947}
					cy={205.533}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-46.607}
					cy={211.108}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-34.122}
					cy={216.312}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-21.482}
					cy={221.129}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-8.698}
					cy={225.545}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={4.223}
					cy={229.543}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={17.269}
					cy={233.111}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={30.428}
					cy={236.233}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={43.687}
					cy={238.898}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={57.035}
					cy={241.067}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={70.456}
					cy={242.718}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={83.929}
					cy={243.805}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={97.475}
					cy={244.262}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={111.079}
					cy={244.334}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={124.63}
					cy={244.007}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={138.111}
					cy={243.031}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={151.543}
					cy={241.479}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={164.906}
					cy={239.4}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={178.183}
					cy={236.823}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={191.362}
					cy={233.785}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={204.43}
					cy={230.298}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={217.374}
					cy={226.378}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={230.185}
					cy={222.039}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={242.851}
					cy={217.297}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={255.363}
					cy={212.167}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={267.73}
					cy={206.667}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={279.858}
					cy={200.682}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={291.722}
					cy={194.206}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={303.41}
					cy={187.402}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={314.931}
					cy={180.314}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={326.262}
					cy={172.938}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-105.578}
					cy={205.839}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-93.249}
					cy={211.972}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-80.917}
					cy={218.091}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-68.491}
					cy={223.992}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-55.888}
					cy={229.55}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-43.172}
					cy={234.86}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-30.449}
					cy={239.931}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-17.337}
					cy={244.208}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-4.029}
					cy={247.799}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={9.378}
					cy={250.984}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={22.86}
					cy={253.792}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={36.396}
					cy={256.325}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={49.979}
					cy={258.564}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={63.607}
					cy={260.496}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={77.27}
					cy={262.077}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={90.962}
					cy={263.159}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={104.812}
					cy={263.488}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={118.657}
					cy={263.203}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={132.349}
					cy={262.161}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={146.015}
					cy={260.615}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={159.644}
					cy={258.698}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={173.232}
					cy={256.486}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={186.77}
					cy={253.967}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={200.257}
					cy={251.175}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={213.669}
					cy={248.021}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={226.984}
					cy={244.465}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={240.127}
					cy={240.242}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={252.842}
					cy={235.164}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={265.513}
					cy={229.775}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={278.109}
					cy={224.21}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={290.621}
					cy={218.458}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={303.019}
					cy={212.479}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={315.345}
					cy={206.343}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-100.265}
					cy={234.558}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-87.662}
					cy={239.832}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-74.965}
					cy={244.873}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-62.173}
					cy={249.668}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-49.289}
					cy={254.208}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-36.313}
					cy={258.48}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-23.248}
					cy={262.467}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={-10.095}
					cy={266.147}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={3.146}
					cy={269.506}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={16.469}
					cy={272.529}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={29.868}
					cy={275.183}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={43.337}
					cy={277.451}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={56.869}
					cy={279.306}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={70.453}
					cy={280.723}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={84.078}
					cy={281.671}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={97.748}
					cy={282.119}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={111.491}
					cy={282.119}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={125.148}
					cy={281.671}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={138.773}
					cy={280.723}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={152.357}
					cy={279.307}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={165.889}
					cy={277.452}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={179.358}
					cy={275.184}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={192.757}
					cy={272.53}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={206.08}
					cy={269.506}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={219.321}
					cy={266.147}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={232.475}
					cy={262.468}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={245.539}
					cy={258.48}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={258.515}
					cy={254.209}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={271.399}
					cy={249.669}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={284.191}
					cy={244.874}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={296.889}
					cy={239.832}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
				<circle
					cx={309.491}
					cy={234.558}
					r={5}
					fill="white"
					className="seat"
					onClick={e => {
						const target = e.target as any;
						if (target.classList.contains('selected')) {
							target.classList.remove('selected');
						} else {
							target.classList.add('selected');
						}
					}}
				/>
			</g>
		</svg>
	);
}

export default Circle3;
