import { Seat } from "../../interfaces";

const seats: Seat[] = [{
  id: "ech-gallery-aa-6",
  x: 489.882,
  y: 145.789
}, {
  id: "ech-gallery-aa-7",
  x: 483.922,
  y: 136.538
}, {
  id: "ech-gallery-aa-8",
  x: 477.255,
  y: 127.739
}, {
  id: "ech-gallery-aa-9",
  x: 470.122,
  y: 119.302
}, {
  id: "ech-gallery-aa-10",
  x: 462.628,
  y: 111.18
}, {
  id: "ech-gallery-aa-11",
  x: 454.805,
  y: 103.373
}, {
  id: "ech-gallery-aa-12",
  x: 446.679,
  y: 95.883
}, {
  id: "ech-gallery-aa-13",
  x: 438.238,
  y: 88.753
}, {
  id: "ech-gallery-aa-14",
  x: 429.455,
  y: 82.056
}, {
  id: "ech-gallery-aa-15",
  x: 420.291,
  y: 75.911
}, {
  id: "ech-gallery-aa-16",
  x: 410.665,
  y: 70.571
}, {
  id: "ech-gallery-aa-17",
  x: 382.681,
  y: 58.837
}, {
  id: "ech-gallery-aa-18",
  x: 371.892,
  y: 56.367
}, {
  id: "ech-gallery-aa-19",
  x: 361.043,
  y: 54.21
}, {
  id: "ech-gallery-aa-20",
  x: 350.145,
  y: 52.333
}, {
  id: "ech-gallery-aa-21",
  x: 339.213,
  y: 50.671
}, {
  id: "ech-gallery-aa-22",
  x: 328.248,
  y: 49.245
}, {
  id: "ech-gallery-aa-23",
  x: 317.261,
  y: 48.014
}, {
  id: "ech-gallery-aa-24",
  x: 306.252,
  y: 46.996
}, {
  id: "ech-gallery-aa-25",
  x: 295.224,
  y: 46.208
}, {
  id: "ech-gallery-aa-26",
  x: 284.184,
  y: 45.638
}, {
  id: "ech-gallery-aa-27",
  x: 273.121,
  y: 45.305
}, {
  id: "ech-gallery-aa-28",
  x: 262.042,
  y: 45.196
}, {
  id: "ech-gallery-aa-29",
  x: 250.624,
  y: 45.347
}, {
  id: "ech-gallery-aa-30",
  x: 238.768,
  y: 45.678
}, {
  id: "ech-gallery-aa-31",
  x: 226.922,
  y: 46.201
}, {
  id: "ech-gallery-aa-32",
  x: 215.084,
  y: 46.917
}, {
  id: "ech-gallery-aa-33",
  x: 203.261,
  y: 47.852
}, {
  id: "ech-gallery-aa-34",
  x: 191.458,
  y: 49.019
}, {
  id: "ech-gallery-aa-35",
  x: 179.68,
  y: 50.412
}, {
  id: "ech-gallery-aa-36",
  x: 167.938,
  y: 52.101
}, {
  id: "ech-gallery-aa-37",
  x: 156.241,
  y: 54.071
}, {
  id: "ech-gallery-aa-38",
  x: 144.613,
  y: 56.433
}, {
  id: "ech-gallery-aa-39",
  x: 133.08,
  y: 59.232
}, {
  id: "ech-gallery-aa-40",
  x: 109.277,
  y: 69.565
}, {
  id: "ech-gallery-aa-41",
  x: 99.65,
  y: 74.906
}, {
  id: "ech-gallery-aa-42",
  x: 90.484,
  y: 81.051
}, {
  id: "ech-gallery-aa-43",
  x: 81.701,
  y: 87.749
}, {
  id: "ech-gallery-aa-44",
  x: 73.26,
  y: 94.879
}, {
  id: "ech-gallery-aa-45",
  x: 65.134,
  y: 102.37
}, {
  id: "ech-gallery-aa-46",
  x: 57.31,
  y: 110.176
}, {
  id: "ech-gallery-aa-47",
  x: 49.817,
  y: 118.297
}, {
  id: "ech-gallery-aa-48",
  x: 42.684,
  y: 126.736
}, {
  id: "ech-gallery-aa-49",
  x: 36.017,
  y: 135.533
}, {
  id: "ech-gallery-aa-50",
  x: 30.059,
  y: 144.783
}, {
  id: "ech-gallery-bb-4",
  x: 506.536,
  y: 137.976
}, {
  id: "ech-gallery-bb-5",
  x: 500.531,
  y: 128.365
}, {
  id: "ech-gallery-bb-6",
  x: 494.063,
  y: 119.058
}, {
  id: "ech-gallery-bb-7",
  x: 487.137,
  y: 110.084
}, {
  id: "ech-gallery-bb-8",
  x: 479.831,
  y: 479.831
}, {
  id: "ech-gallery-bb-9",
  x: 472.137,
  y: 93.092
}, {
  id: "ech-gallery-bb-10",
  x: 464.035,
  y: 85.164
}, {
  id: "ech-gallery-bb-11",
  x: 455.577,
  y: 77.619
}, {
  id: "ech-gallery-bb-12",
  x: 446.764,
  y: 70.493
}, {
  id: "ech-gallery-bb-13",
  x: 437.548,
  y: 63.897
}, {
  id: "ech-gallery-bb-14",
  x: 427.967,
  y: 57.851
}, {
  id: "ech-gallery-bb-15",
  x: 418.04,
  y: 52.395
}, {
  id: "ech-gallery-bb-16",
  x: 393.754,
  y: 42.85
}, {
  id: "ech-gallery-bb-17",
  x: 382.296,
  y: 39.825
}, {
  id: "ech-gallery-bb-18",
  x: 370.754,
  y: 37.155
}, {
  id: "ech-gallery-bb-19",
  x: 359.148,
  y: 34.799
}, {
  id: "ech-gallery-bb-20",
  x: 347.493,
  y: 32.697
}, {
  id: "ech-gallery-bb-21",
  x: 335.797,
  y: 30.845
}, {
  id: "ech-gallery-bb-22",
  x: 324.064,
  y: 29.255
}, {
  id: "ech-gallery-bb-23",
  x: 312.301,
  y: 27.901
}, {
  id: "ech-gallery-bb-24",
  x: 300.513,
  y: 26.805
}, {
  id: "ech-gallery-bb-25",
  x: 288.701,
  y: 26.011
}, {
  id: "ech-gallery-bb-26",
  x: 276.874,
  y: 25.514
}, {
  id: "ech-gallery-bb-27",
  x: 264.989,
  y: 25.313
}, {
  id: "ech-gallery-bb-28",
  x: 253.162,
  y: 25.314
}, {
  id: "ech-gallery-bb-29",
  x: 241.321,
  y: 25.37
}, {
  id: "ech-gallery-bb-30",
  x: 229.967,
  y: 26.082
}, {
  id: "ech-gallery-bb-31",
  x: 219.009,
  y: 26.54
}, {
  id: "ech-gallery-bb-32",
  x: 208.054,
  y: 27.073
}, {
  id: "ech-gallery-bb-33",
  x: 197.106,
  y: 27.758
}, {
  id: "ech-gallery-bb-34",
  x: 186.172,
  y: 28.634
}, {
  id: "ech-gallery-bb-35",
  x: 175.266,
  y: 29.802
}, {
  id: "ech-gallery-bb-36",
  x: 164.4,
  y: 31.284
}, {
  id: "ech-gallery-bb-37",
  x: 153.601,
  y: 33.183
}, {
  id: "ech-gallery-bb-38",
  x: 142.881,
  y: 35.478
}, {
  id: "ech-gallery-bb-39",
  x: 132.275,
  y: 38.241
}, {
  id: "ech-gallery-bb-40",
  x: 121.788,
  y: 41.423
}, {
  id: "ech-gallery-bb-41",
  x: 101.899,
  y: 51.391
}, {
  id: "ech-gallery-bb-42",
  x: 91.973,
  y: 56.847
}, {
  id: "ech-gallery-bb-43",
  x: 82.391,
  y: 62.893
}, {
  id: "ech-gallery-bb-44",
  x: 73.176,
  y: 69.49
}, {
  id: "ech-gallery-bb-45",
  x: 64.363,
  y: 76.614
}, {
  id: "ech-gallery-bb-46",
  x: 55.905,
  y: 84.16
}, {
  id: "ech-gallery-bb-47",
  x: 47.803,
  y: 92.087
}, {
  id: "ech-gallery-bb-48",
  x: 40.108,
  y: 100.412
}, {
  id: "ech-gallery-bb-49",
  x: 32.802,
  y: 109.08
}, {
  id: "ech-gallery-bb-50",
  x: 25.877,
  y: 118.053
}, {
  id: "ech-gallery-bb-51",
  x: 19.408,
  y: 127.361
}, {
  id: "ech-gallery-bb-52",
  x: 13.403,
  y: 136.972
}, {
  id: "ech-gallery-cc-1",
  x: 523.777,
  y: 128.813
}, {
  id: "ech-gallery-cc-2",
  x: 518.429,
  y: 119.554
}, {
  id: "ech-gallery-cc-3",
  x: 512.336,
  y: 110.84
}, {
  id: "ech-gallery-cc-4",
  x: 505.673,
  y: 102.53
}, {
  id: "ech-gallery-cc-5",
  x: 498.689,
  y: 94.478
}, {
  id: "ech-gallery-cc-6",
  x: 491.462,
  y: 86.641
}, {
  id: "ech-gallery-cc-7",
  x: 484.025,
  y: 79.003
}, {
  id: "ech-gallery-cc-8",
  x: 476.354,
  y: 71.596
}, {
  id: "ech-gallery-cc-9",
  x: 468.723,
  y: 64.153
}, {
  id: "ech-gallery-cc-10",
  x: 460.916,
  y: 56.906
}, {
  id: "ech-gallery-cc-11",
  x: 452.587,
  y: 50.264
}, {
  id: "ech-gallery-cc-12",
  x: 443.839,
  y: 44.171
}, {
  id: "ech-gallery-cc-13",
  x: 434.799,
  y: 38.51
}, {
  id: "ech-gallery-cc-14",
  x: 425.559,
  y: 33.183
}, {
  id: "ech-gallery-cc-15",
  x: 407.457,
  y: 25.581
}, {
  id: "ech-gallery-cc-16",
  x: 396.471,
  y: 22.292
}, {
  id: "ech-gallery-cc-17",
  x: 385.39,
  y: 19.332
}, {
  id: "ech-gallery-cc-18",
  x: 374.216,
  y: 16.733
}, {
  id: "ech-gallery-cc-19",
  x: 362.961,
  y: 14.5
}, {
  id: "ech-gallery-cc-20",
  x: 351.638,
  y: 12.602
}, {
  id: "ech-gallery-cc-21",
  x: 340.279,
  y: 10.948
}, {
  id: "ech-gallery-cc-22",
  x: 328.908,
  y: 9.474
}, {
  id: "ech-gallery-cc-23",
  x: 317.5,
  y: 8.223
}, {
  id: "ech-gallery-cc-24",
  x: 306.074,
  y: 7.175
}, {
  id: "ech-gallery-cc-25",
  x: 294.633,
  y: 6.311
}, {
  id: "ech-gallery-cc-26",
  x: 283.182,
  y: 5.632
}, {
  id: "ech-gallery-cc-27",
  x: 271.703,
  y: 5.14
}, {
  id: "ech-gallery-cc-28",
  x: 260.27,
  y: 4.867
}, {
  id: "ech-gallery-cc-29",
  x: 248.766,
  y: 4.954
}, {
  id: "ech-gallery-cc-30",
  x: 237.277,
  y: 5.198
}, {
  id: "ech-gallery-cc-31",
  x: 225.808,
  y: 5.569
}, {
  id: "ech-gallery-cc-32",
  x: 214.346,
  y: 6.116
}, {
  id: "ech-gallery-cc-33",
  x: 202.895,
  y: 6.863
}, {
  id: "ech-gallery-cc-34",
  x: 191.461,
  y: 7.84
}, {
  id: "ech-gallery-cc-35",
  x: 180.053,
  y: 9.07
}, {
  id: "ech-gallery-cc-36",
  x: 168.678,
  y: 10.586
}, {
  id: "ech-gallery-cc-37",
  x: 157.349,
  y: 12.409
}, {
  id: "ech-gallery-cc-38",
  x: 146.082,
  y: 14.579
}, {
  id: "ech-gallery-cc-39",
  x: 134.893,
  y: 17.115
}, {
  id: "ech-gallery-cc-40",
  x: 123.798,
  y: 20.036
}, {
  id: "ech-gallery-cc-41",
  x: 112.816,
  y: 23.355
}, {
  id: "ech-gallery-cc-42",
  x: 94.38,
  y: 32.18
}, {
  id: "ech-gallery-cc-43",
  x: 85.14,
  y: 37.505
}, {
  id: "ech-gallery-cc-44",
  x: 76.1,
  y: 43.167
}, {
  id: "ech-gallery-cc-45",
  x: 67.353,
  y: 49.26
}, {
  id: "ech-gallery-cc-46",
  x: 59.025,
  y: 55.902
}, {
  id: "ech-gallery-cc-47",
  x: 51.215,
  y: 63.149
}, {
  id: "ech-gallery-cc-48",
  x: 43.584,
  y: 70.593
}, {
  id: "ech-gallery-cc-49",
  x: 35.913,
  y: 77.999
}, {
  id: "ech-gallery-cc-50",
  x: 28.477,
  y: 85.637
}, {
  id: "ech-gallery-cc-51",
  x: 21.25,
  y: 93.474
}, {
  id: "ech-gallery-cc-52",
  x: 14.266,
  y: 101.527
}, {
  id: "ech-gallery-cc-53",
  x: 7.603,
  y: 109.835
}, {
  id: "ech-gallery-cc-54",
  x: 1.513,
  y: 118.546
}, {
  id: "ech-gallery-cc-55",
  x: -3.839,
  y: 127.81
}, {
  id: "ech-gallery-dd-1",
  x: 539.704,
  y: 116.112
}, {
  id: "ech-gallery-dd-2",
  x: 533.243,
  y: 106.784
}, {
  id: "ech-gallery-dd-3",
  x: 526.47,
  y: 97.681
}, {
  id: "ech-gallery-dd-5",
  x: 513.863,
  y: 80.452
}, {
  id: "ech-gallery-dd-6",
  x: 506.028,
  y: 72.041
}, {
  id: "ech-gallery-dd-7",
  x: 497.87,
  y: 63.944
}, {
  id: "ech-gallery-dd-8",
  x: 489.41,
  y: 56.163
}, {
  id: "ech-gallery-dd-9",
  x: 480.653,
  y: 48.718
}, {
  id: "ech-gallery-dd-10",
  x: 471.601,
  y: 41.635
}, {
  id: "ech-gallery-dd-12",
  x: 454.66,
  y: 29.614
}, {
  id: "ech-gallery-dd-13",
  x: 445.3,
  y: 23.56
}, {
  id: "ech-gallery-dd-14",
  x: 435.71,
  y: 17.878
}, {
  id: "ech-gallery-dd-15",
  x: 405.721,
  y: 4.869
}, {
  id: "ech-gallery-dd-16",
  x: 394.419,
  y: 1.842
}, {
  id: "ech-gallery-dd-17",
  x: 383.038,
  y: -0.853
}, {
  id: "ech-gallery-dd-18",
  x: 371.598,
  y: -3.278
}, {
  id: "ech-gallery-dd-19",
  x: 360.109,
  y: -5.457
}, {
  id: "ech-gallery-dd-20",
  x: 348.581,
  y: -7.41
}, {
  id: "ech-gallery-dd-22",
  x: 332.207,
  y: -9.794
}, {
  id: "ech-gallery-dd-23",
  x: 320.148,
  y: -11.259
}, {
  id: "ech-gallery-dd-24",
  x: 308.063,
  y: -12.475
}, {
  id: "ech-gallery-dd-25",
  x: 295.954,
  y: -13.422
}, {
  id: "ech-gallery-dd-26",
  x: 283.826,
  y: -14.078
}, {
  id: "ech-gallery-dd-27",
  x: 271.672,
  y: -14.421
}, {
  id: "ech-gallery-dd-29",
  x: 243.978,
  y: -14.267
}, {
  id: "ech-gallery-dd-30",
  x: 231.638,
  y: -14.021
}, {
  id: "ech-gallery-dd-31",
  x: 219.28,
  y: -13.775
}, {
  id: "ech-gallery-dd-32",
  x: 206.954,
  y: -13.268
}, {
  id: "ech-gallery-dd-33",
  x: 194.633,
  y: -12.471
}, {
  id: "ech-gallery-dd-34",
  x: 182.333,
  y: -11.406
}, {
  id: "ech-gallery-dd-36",
  x: 163.53,
  y: -9.105
}, {
  id: "ech-gallery-dd-37",
  x: 152.412,
  y: -7.289
}, {
  id: "ech-gallery-dd-38",
  x: 141.363,
  y: -5.101
}, {
  id: "ech-gallery-dd-39",
  x: 130.4,
  y: -2.522
}, {
  id: "ech-gallery-dd-40",
  x: 119.535,
  y: 0.438
}, {
  id: "ech-gallery-dd-41",
  x: 108.782,
  y: 3.785
}, {
  id: "ech-gallery-dd-42",
  x: 84.229,
  y: 16.875
}, {
  id: "ech-gallery-dd-43",
  x: 74.639,
  y: 22.557
}, {
  id: "ech-gallery-dd-44",
  x: 65.279,
  y: 28.61
}, {
  id: "ech-gallery-dd-46",
  x: 48.338,
  y: 40.631
}, {
  id: "ech-gallery-dd-47",
  x: 39.286,
  y: 47.713
}, {
  id: "ech-gallery-dd-48",
  x: 30.529,
  y: 55.159
}, {
  id: "ech-gallery-dd-49",
  x: 22.069,
  y: 62.94
}, {
  id: "ech-gallery-dd-50",
  x: 13.91,
  y: 71.037
}, {
  id: "ech-gallery-dd-51",
  x: 6.076,
  y: 79.448
}, {
  id: "ech-gallery-dd-53",
  x: -6.532,
  y: 96.678
}, {
  id: "ech-gallery-dd-54",
  x: -13.304,
  y: 105.78
}, {
  id: "ech-gallery-dd-55",
  x: -19.765,
  y: 115.107
}]

export default seats